export const LeftArrow = ({width = 13, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrow - Left">
      <path
        id="Arrow"
        d="M1.0281 0.643369C0.668519 1.00295 0.63583 1.56563 0.93003 1.96213L1.0281 2.07573L9.76454 10.8126L1.0281 19.5495C0.668519 19.9091 0.63583 20.4718 0.93003 20.8683L1.0281 20.9819C1.38767 21.3415 1.95035 21.3742 2.34686 21.08L2.46045 20.9819L11.9135 11.5288C12.2731 11.1692 12.3058 10.6066 12.0116 10.21L11.9135 10.0965L2.46045 0.643369C2.06492 0.247834 1.42363 0.247834 1.0281 0.643369Z"
        fill="#757575"
      />
    </g>
  </svg>
);
