import { useState } from "react";

import { MEDIA_FORMATS } from "../../../../constants/detailedProject";
import { MediaCard } from "../../../../components/mediaCard";

import "./styles.scss";

export const ProjectPost = ({ note, media, setIsRemoved, rating, profile }) => {
  const [isError, setIsError] = useState(false);
  const { media_url, media_type, children: childrenMedia } = media;
  return (
    <div className="projectPost">
      <MediaCard
        isModal={false}
        post={media}
        note={note}
        isRemovable={true}
        setIsRemoved={setIsRemoved}
        rating={rating}
        profile={profile}
      >
        {isError ||
          (!media_url && (
            <div className="projectPost_upper_error">
              <span className="projectPost_upper_error_text">
                Not available
              </span>
            </div>
          ))}
        {media_type === MEDIA_FORMATS.img && media_url && (
          <img
            src={media_url}
            alt="postImage"
            className="projectPost_upper_img"
          />
        )}
        {media_type === MEDIA_FORMATS.vid && media_url && (
          <video
            alt="postImage"
            className="projectPost_upper_video"
            controls
            onError={() => setIsError(true)}
          >
            <source src={media_url} />
          </video>
        )}
        {media_type === MEDIA_FORMATS.carousel && media_url && (
          <>
            {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.img && (
              <img
                src={childrenMedia?.data[0]?.media_url}
                alt="postImage"
                className="projectPost_upper_img"
              />
            )}
            {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.vid && (
              <video
                alt="postImage"
                className="projectPost_upper_video"
                controls
                onError={() => setIsError(true)}
              >
                <source src={childrenMedia?.data[0]?.media_url} />
              </video>
            )}
          </>
        )}
        {media_type === MEDIA_FORMATS.carouselTikTok && childrenMedia && (
          <>
            {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.img && (
              <img
                src={childrenMedia?.data[0]?.media_url}
                alt="postImage"
                className="projectPost_upper_img"
              />
            )}
            {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.vid && (
              <video
                alt="postImage"
                className="projectPost_upper_video"
                controls
                onError={() => setIsError(true)}
              >
                <source src={childrenMedia?.data[0]?.media_url} />
              </video>
            )}
          </>
        )}
      </MediaCard>
    </div>
  );
};
