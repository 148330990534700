import { useEffect, useState } from "react";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../constants/navigation";
import { UserOutlined } from "@ant-design/icons";
import { deleteCookie, getCookie } from "../../utils/cookie";

import "./styles.scss";

export const UserAccount = ({ isHidden }) => {
  const [firstCharacter, setFirstCharacter] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("userEmail");
    setFirstCharacter(user ? user[0] : <UserOutlined />);
  }, []);

  const logoutHandler = () => {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    localStorage.removeItem("userEmail");
    navigate(NAVIGATION.root);
  };
  return (
    <>
      {!isHidden && getCookie("refreshToken") && (
        <div className="userAccount_logout">
          <div className="userAccount_logout_acc">
            <Avatar
              style={{
                backgroundColor: "#aa9165",
                color: "white",
                textTransform: "uppercase",
              }}
              size={"large"}
              icon={firstCharacter}
            />
          </div>
          <button className="userAccount_logout_btn" onClick={logoutHandler}>
            Logout
          </button>
        </div>
      )}
    </>
  );
};
