import { formatDate } from "./formatData";

export const generateDefaultValue = (item) => {
  return `${
    item.note ??
    `${formatDate(item.timestamp)}\nLikes: ${
      item.like_count?.toLocaleString("en-US") ?? "-"
    }\nComments: ${
      item.comments_count?.toLocaleString("en-US") ?? "-"
    }\nShares: ${
      item.share_count?.toLocaleString("en-US") ?? "-"
    }\nDownloads: ${
      item.download_count?.toLocaleString("en-US") ?? "-"
    }\nSaved: ${item.collect_count?.toLocaleString("en-US") ?? "-"}`
  }`;
};
