import { API_METHODS } from "../constants/api";
import { fetchAPI } from "../utils/fetchAPI";
import { getParsedLinkInfo } from "./getParsedLinkInfo";
import { getSavedMedia } from "./mediaRequests";

const parseInstaData = (data, media) => {
  return data.media.map((item) => {
    const checkedItem = media.data.savedMedias.find(
      (checkedItem) =>
        checkedItem.media.id === item.id ||
        checkedItem.media.permalink === item.permalink
    );
    if (checkedItem) {
      return { ...item, isSaved: true, note: checkedItem?.note };
    }
    const itemWithNote = media.data.mediaWithNotes.find(
      (checkedItem) =>
        checkedItem.media.id === item.id ||
        checkedItem.media.permalink === item.permalink
    );
    return { ...item, isSaved: false, note: itemWithNote?.note };
  });
};

const getInstaData = async (url, media, ratingPosts) => {
  const response = await fetchAPI(url, API_METHODS.get);
  if (!response?.success) throw new Error(response.message);
  return {
    media: parseInstaData(response.data, media),
    profile: response.data.profile,
    rating: ratingPosts,
  };
};

export const getInstagramPosts = async (
  link,
  dateLimit,
  ratingPosts,
  errorHandler
) => {
  try {
    const user = await getParsedLinkInfo(link);
    if (user.message === "User unauthorized" && !user.success)
      throw new Error(user.message);
    if (!user || !user.success) throw new Error("Bad URL");

    const { username, type } = user.data;
    const url = `/project/${type}?username=${encodeURIComponent(
      username
    )}&toDate=${dateLimit}&rating=${ratingPosts}`;

    const media = await getSavedMedia(username, type);
    const data = await getInstaData(url, media, ratingPosts);
    if (data.media.length === 0) throw new Error("Posts not found");
    return data;
  } catch (e) {
    errorHandler(e.message);
    return null;
  }
};
