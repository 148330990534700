import React from "react";

const CheckboxIcon = ({ markerColor = "black", borderColor = "black", borderRadius = 0, markerSize = "3" }) => (
    <svg
    className="checkbox__icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <rect
      width="21"
      height="21"
      x=".5"
      y=".5"
      fill="#FFF"
      stroke={borderColor}
      rx={borderRadius}
      strokeWidth={0.8}
    />
    <path
      className="tick"
      stroke={markerColor}
      fill="none"
      strokeWidth={markerSize}
      d="M4 10l5 5 9-9"
    />
  </svg>
);

export default CheckboxIcon;