import { Link } from "react-router-dom";
import { NAVIGATION } from "../../constants/navigation";

import creatrixImg from "../../img/creatrixImg.png";

export const NavigationLogo = ({ className }) => (
  <Link to={NAVIGATION.root} className="link" style={{ height: "min-content" }}>
    <img src={creatrixImg} alt="creatrixImg" className={className} />
  </Link>
);
