export const NAVIGATION = {
  root: "/",
  home: "/home",
  auth: "/auth",
  select: "/select",
  posts: "/posts",
  project: "/project",
  detailedProject: "/project/:projectId",
  terms: "/terms",
  privacy: "/privacy",
};
