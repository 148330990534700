import { refreshToken } from "../api/auth";
import { API_TOKENS, API_URL } from "../constants/api";
import {
  ACCESS_TOKEN_EXPIRES,
  REFRESH_TOKEN_EXPIRES,
} from "../constants/cookieExpires";
import { NAVIGATION } from "../constants/navigation";
import { getCookie, setCookie } from "./cookie";
import { userReset } from "./userReset";

export const fetchAPI = async (url, method, body, token = null) => {
  const accessToken = getCookie(API_TOKENS.access);
  const oldToken = token || getCookie(API_TOKENS.refresh);
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
      timeout: 180000,
    });
    const data = await response.json();

    if (!data.success && data.message === "jwt expired") {
      userReset();
      const token = await refreshToken(oldToken);
      if (token.success) {
        setCookie(API_TOKENS.access, token.accessToken, ACCESS_TOKEN_EXPIRES);
        setCookie(
          API_TOKENS.refresh,
          token.refreshToken,
          REFRESH_TOKEN_EXPIRES
        );

        return await fetchAPI(url, method, body, token.refreshToken);
      } else if (
        !getCookie(API_TOKENS.refresh) &&
        !getCookie(API_TOKENS.access)
      ) {
        return (window.location.href = NAVIGATION.auth);
      }
    } else if (data.message === "User is blocked") {
      userReset();
    } else if (data.message === "jwt malformed") {
      userReset();
      throw new Error("User unauthorized");
    }

    return data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};
