import { Path, Svg } from "@react-pdf/renderer";

export const HeartIcon = ({ width = 8, height = 8 }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M3.34005 5.76272C2.96716 5.41469 2.75586 4.91751 2.75586 4.39546V4.33332C2.75586 3.46324 3.37734 2.71746 4.23498 2.58074C4.80675 2.4813 5.37851 2.66775 5.78869 3.06549L5.93784 3.21465L6.087 3.06549C6.48474 2.66775 7.06894 2.4813 7.62827 2.58074C8.48591 2.71746 9.11982 3.46324 9.11982 4.33332V4.39546C9.11982 4.91751 8.89609 5.41469 8.5232 5.76272L6.27344 7.86333C6.18643 7.95033 6.06214 7.98762 5.93784 7.98762C5.80112 7.98762 5.67682 7.95033 5.58981 7.86333L3.34005 5.76272Z"
      fill="#AA9165"
    />
  </Svg>
);
