import { SelectLayout, Button } from "../../components";
import { NAVIGATION } from "../../constants/navigation";

import "./styles.scss";

const descriptionText = "Your all-in-one content marketing generator";

export const SelectScreen = () => (
  <SelectLayout
    text={descriptionText}
    title={true}
    isPrivacy={true}
    isProfile={true}
  >
    <div className="selectScreen">
      <div className="selectScreen_button margined">
        <Button link={NAVIGATION.home}>Public Audit</Button>
      </div>
      <div className="selectScreen_button">
        <Button
          className="selectScreen_button_projects"
          link={NAVIGATION.project}
        >
          Saved Projects
        </Button>
      </div>
    </div>
  </SelectLayout>
);
