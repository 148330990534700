import { Link } from "react-router-dom";
import { NAVIGATION } from "../../constants/navigation";

import creatrixImg from "../../img/creatrixImg.png";

import "./styles.scss";

export const AuditLayout = ({ children, title }) => (
  <div className="auditLayout">
    <div className="auditLayout_container">
      <Link to={NAVIGATION.root} className="auditLayout_img_link">
        <img className="auditLayout_img" src={creatrixImg} alt="creatrixImg" />
      </Link>
      <span className="auditLayout_title">{title}</span>
    </div>
    {children}
  </div>
);
