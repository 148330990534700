import { useState } from "react";
import Slider from "react-slick";
import { MEDIA_FORMATS } from "../../../../constants/detailedProject";
import { ErrorMedia } from "./errorMedia";

export const Media = ({ type, url, i, videoRefs, posts }) => {
  const [isError, setIsError] = useState(false);

  const carouselSettings = {
    dots: true,
    appendDots: (dots) => (
      <ul style={{ bottom: "33px", height: "0.1px" }}>{dots}</ul>
    ),
    infinite: false,
    speed: 500,
    arrows: false,
    className: "slick_slider",
  };

  if (!url || isError) return <ErrorMedia type={"slider"} />;
  
  if (type === MEDIA_FORMATS.img && posts) {
    return <img src={url} alt="postImage" className="slider_media" />;
  } else if (type === MEDIA_FORMATS.vid && posts) {
    return (
      <video
        alt="postImage"
        className="slider_media"
        controls
        ref={(ref) => (videoRefs.current[i] = ref)}
        onError={() => {
          setIsError(true);
        }}
      >
        <source src={url} type="video/mp4" />
      </video>
    );
  } else if (type === MEDIA_FORMATS.carousel && posts) {
    return (
      <>
        <Slider {...carouselSettings} className="slider_carousel">
          {posts[i].children.data.map((item, index) => (
            <div className="slider_carousel_box" key={index}>
              {item.media_type === MEDIA_FORMATS.img ? (
                <img
                  src={item.media_url}
                  alt="postImage"
                  className="slider_carousel_media"
                />
              ) : (
                <video
                  alt="postImage"
                  className="slider_carousel_media slider_carousel_video"
                  controls
                  ref={(ref) => (videoRefs.current[i] = ref)}
                >
                  <source src={item.media_url} type="video/mp4" />
                </video>
              )}
            </div>
          ))}
        </Slider>
      </>
    );
  } else if (type === MEDIA_FORMATS.carouselTikTok && posts) {
    return (
      <>
        <Slider {...carouselSettings} className="slider_carousel">
          {posts[i].children.data.map((item, index) => (
            <div key={index}>
              <img
                src={item.media_url}
                alt="postImage"
                className="slider_carousel_media_tiktok"
              />
            </div>
          ))}
        </Slider>
        <div className="slider_carousel_box_tiktok">
          <video
            alt="postImage"
            className="slider_carousel_media slider_carousel_audio"
            controls
            ref={(ref) => (videoRefs.current[i] = ref)}
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>
      </>
    );
  }
};
