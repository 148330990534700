import { Link } from "react-router-dom";
import { NAVIGATION } from "../../constants/navigation";
import { LINKS } from "../../constants/links";

import posterProfile from "../../img/poster/posterProfile.png";
import posterPosts from "../../img/poster/posterPosts.png";
import creatrixImg from "../../img/creatrixImg.png";

import "./styles.scss";
import { UserAccount } from "../userAccount";

export const SelectLayout = ({
  children,
  text = "",
  title = true,
  isPrivacy = false,
  isProfile = false,
  onSubmit,
}) => {
  const poster = isProfile ? posterProfile : posterPosts;
  const descriptionStyle = title
    ? "selectLayout_title_text"
    : "selectLayout_description_text";
  return (
    <div className="selectLayout">
      <img
        src={poster}
        className="selectLayout_poster"
        alt="poster with posts"
      />
      <div className="selectLayout_account">
        <UserAccount isHidden={onSubmit} />
      </div>
      <div className="selectLayout_column">
        <div className="selectLayout_container">
          <Link to={NAVIGATION.root} className="selectLayout_img_link">
            <img
              className="selectLayout_img"
              src={creatrixImg}
              alt="creatrixImg"
            />
          </Link>
          <div className="selectLayout_title">
            <span className={descriptionStyle}>{text}</span>
          </div>
        </div>

        {children}
        {isPrivacy && (
          <div className="selectLayout_privacy">
            <Link to={NAVIGATION.privacy} className="selectLayout_privacy_link">
              Privacy Policy{" "}
            </Link>
            |
            <Link to={NAVIGATION.terms} className="selectLayout_privacy_link">
              {" "}
              Terms of Service
            </Link>
            <Link
              to={LINKS.recomendedPage}
              className="selectLayout_privacy_link"
              target="_blank"
            >
              {" "}
              | Recommended Reading
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
