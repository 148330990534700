import { StyleSheet } from "@react-pdf/renderer";

const colorBeige = "#ae9060";

export const detailedMixins = StyleSheet.create({
  btn_box: {
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    padding: "0 10px",
    borderRadius: "50px",
    height: "25px",
    marginRight: "10px",
  },
  btn_text: {
    textAlign: "center",
    color: "white",
    fontFamily: "Roboto",
    fontSize: "7px",
  },

  noteBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 0 20px",
    padding: "7px 5px 3px",
    width: "60%",
    border: "0.7px solid #D9D9D9",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "10px",
    marginBottom: "5px",
  },
  info: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "8px",
  },
});

export const auditLayout = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "200px",
    objectFit: "contain",
  },
  titleBox: {
    display: "flex",
    margin: "30px 0",
    marginRight: "-20.15px",
    alignContent: "center",
  },
  title: {
    color: "#2F2F2F",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "20.15px",
  },
});

export const detailedUpper = StyleSheet.create({
  pdfPage: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "25px",
  },
  upper_block: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
  },
  upper_block_left: {
    display: "flex",
    flexDirection: "row",
  },
  upper_block_right: {
    display: "flex",
    flexDirection: "row",
  },

  btn_back: {
    ...detailedMixins.btn_text,
    color: "white",
  },
  btn_selected: {
    ...detailedMixins.btn_text,
    color: "#aa9165",
  },
  btn_unselected: {
    ...detailedMixins.btn_text,
    color: "white",
  },
  btn_pdf: {
    ...detailedMixins.btn_text,
    color: "white",
  },

  back_block: {
    ...detailedMixins.btn_box,
    backgroundColor: "black",
    width: "40px",
  },
  selected_block: {
    ...detailedMixins.btn_box,
    backgroundColor: "white",
    border: `0.5px solid ${colorBeige}`,
  },
  unselected_block: {
    ...detailedMixins.btn_box,
    backgroundColor: "#ae9060",
    border: `0.5px solid ${colorBeige}`,
  },
  pdf_block: {
    ...detailedMixins.btn_box,
    margin: "0",
    marginLeft: "10px",
    backgroundColor: `${colorBeige}`,
  },

  label_container: {
    ...detailedMixins.btn_box,
    padding: "0 10px",
    justifyContent: "flex-start",
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    border: `0.5px solid ${colorBeige}`,
  },
  label_text_block: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "5px",
  },
  label_text: {
    ...detailedMixins.btn_text,
    fontSize: "7px",
    color: colorBeige,
    fontWeight: "bold",
  },
  label_img: {
    width: "15px",
    height: "15px",
    backgroundColor: "white",
  },

  logo_img: {
    width: "25%",
    height: "15px",
    objectFit: "contain",
  },
});

export const detailedStrategy = StyleSheet.create({
  block: { ...detailedMixins.noteBlock },
  title: { ...detailedMixins.title },
  info: { ...detailedMixins.info },
});

export const detailedNotes = StyleSheet.create({
  block: {
    display: "flex",
    alignContent: "flex-start",
    width: "100%",
  },
  blockNote: { ...detailedMixins.noteBlock, width: "100%" },
  title: { ...detailedMixins.title, margin: 0 },
  info: { ...detailedMixins.info },
  numbering: {
    fontSize: "8px",
    fontFamily: "Fira Code",
  },
  noteRow: {
    flexDirection: "row",
    width: "100%",
    marginBottom: "5px",
  },
});

export const mainStyles = StyleSheet.create({
  postsBlock: {
    paddingVertical: "10px",
    width: "100%",
    marginBottom: "30px",
  },
  postsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: "28px",
  },
});

export const postStyles = StyleSheet.create({
  postContainer: {
    display: "flex",
    width: "135px",
    flexDirection: "column",
  },

  postContainer_upper: {
    width: "100%",
    height: "140px",
  },

  postContainer_upper_error: {
    width: "100%",
    height: "140px",
    backgroundColor: "black",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
  },

  postContainer_img: {
    width: "100%",
    objectFit: "cover",
    height: "140px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
  },

  postContainer_bottom: {
    display: "flex",
    width: "100%",
    minHeight: "25px",
    padding: "5px 6px",
    flexDirection: "column",
    backgroundColor: "#F6F2EE",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "2px",
  },
  postContainer_bottom_section: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 2,
  },
  postContainer_bottom_section_text: {
    color: "#000",
    fontFamily: "Roboto",
    fontSize: "4px",
    fontWeight: "extralight",
    lineHeight: "166%",
    letterSpacing: "0.336px",
    textAlign: "center",
  },
  postContainer_bottom_text: {
    color: "#000",
    fontSize: "6px",
    fontWeight: "normal",
    lineHeight: "166%",
    letterSpacing: "0.336px",
    fontFamily: "Fira Code",
  },
  not_found: {
    width: "100%",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Fira Code",
    padding: "3px",
    textAlign: "center",
  },
});
