import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import { SlickSliderItem } from "./slide";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { LeftArrow } from "../../../img/icons/leftArrow";
import { RightArrow } from "../../../img/icons/rightArrow";

function SampleNextArrow(props) {
  const { style, onClick, currentSlide, slideCount, changeSlideHandler } =
    props;
  changeSlideHandler(currentSlide - 1);
  return (
    <>
      {currentSlide !== slideCount - 1 && (
        <div
          style={{ ...style, zIndex: "999" }}
          className={`slider_next_arrow`}
          onClick={onClick}
        >
          <LeftArrow />
        </div>
      )}
    </>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick, currentSlide, changeSlideHandler } = props;
  changeSlideHandler(currentSlide + 1);
  return (
    <>
      {currentSlide !== 0 && (
        <div
          style={{ ...style, zIndex: "999" }}
          className={`slider_prev_arrow`}
          onClick={onClick}
        >
          <RightArrow />
        </div>
      )}
    </>
  );
}

export const SlickSlider = ({
  posts,
  currentPage,
  savePost,
  isActive,
  setPosts,
  ratingPosts,
  profile,
}) => {
  const slider = useRef(null);
  const videoRefs = useRef([]);

  const changeSlideHandler = (postNumber) => {
    const currentVideoRef = videoRefs.current[postNumber];
    if (currentVideoRef) currentVideoRef.pause();
  };

  const [settings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow changeSlideHandler={changeSlideHandler} />,
    prevArrow: <SamplePrevArrow changeSlideHandler={changeSlideHandler} />,
    swipe: false,
    ref: slider,
  });

  useEffect(() => {
    changeSlideHandler(currentPage);
    slider.current.slickGoTo(currentPage, true);
  }, [isActive, currentPage]);

  return (
    <div>
      {posts && (
        <Slider {...settings} className="slider">
          {posts.map((item, i) => (
            <SlickSliderItem
              rating={ratingPosts}
              profile={profile}
              posts={posts}
              setPosts={setPosts}
              item={item}
              i={i}
              videoRefs={videoRefs}
              key={i}
              savePost={savePost}
            />
          ))}
        </Slider>
      )}
    </div>
  );
};
