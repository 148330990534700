import { fetchAPI } from "../utils/fetchAPI";
import { API_METHODS, API_ROUTES } from "../constants/api";

export const saveMedia = (id, username, socialNetwork, rating) => {
  return fetchAPI(API_ROUTES.saveMedia, API_METHODS.post, {
    id,
    rating,
    username: encodeURIComponent(username),
    socialNetwork,
  });
};

export const removeMedia = (id, username, socialNetwork) => {
  return fetchAPI(API_ROUTES.removeMedia, API_METHODS.post, {
    id,
    username: encodeURIComponent(username),
    socialNetwork,
  });
};

export const saveNotes = (id, note, username, socialNetwork, rating) => {
  return fetchAPI(API_ROUTES.saveNotes, API_METHODS.post, {
    id,
    note,
    rating,
    username: encodeURIComponent(username),
    socialNetwork,
  });
};

export const saveProjectNotes = (username, socialNetwork, note) => {
  return fetchAPI(API_ROUTES.saveProjectNotes, API_METHODS.post, {
    username: encodeURIComponent(username),
    socialNetwork,
    note,
  });
};

export const getSavedMedia = (username, type) => {
  return fetchAPI(
    `${API_ROUTES.getSavedMedia}${encodeURIComponent(username)}${
      API_ROUTES.getSavedMediaSocial
    }${type}`,
    API_METHODS.get
  );
};

export const getSavedProjects = () => {
  return fetchAPI(API_ROUTES.getSavedProjects, API_METHODS.get);
};

export const getProject = (username, socialNetwork) => {
  return fetchAPI(
    `${API_ROUTES.getProject}${encodeURIComponent(
      username
    )}?socialNetwork=${socialNetwork}`,
    API_METHODS.get
  );
};

export const getPostGPTResponse = (
  postId,
  questionId,
  method = API_METHODS.get,
  body
) => {
  return fetchAPI(
    `${API_ROUTES.getGPTResponse}?contentId=${postId}&questionId=${questionId}`,
    method,
    body
  );
};

export const getProjectGPTResponse = (username, socialNetwork, questionId) => {
  return fetchAPI(
    `${API_ROUTES.getGPTResponse}?contentId=${encodeURIComponent(
      username
    )}&socialNetwork=${socialNetwork}&questionId=${questionId}`,
    API_METHODS.get
  );
};

export const removeGPTResponse = (responseId) => {
  return fetchAPI(
    `${API_ROUTES.removeGPTResponse}${responseId}`,
    API_METHODS.delete
  );
};

export const getGPTQuestions = () => {
  return fetchAPI(API_ROUTES.getGPTQuestions, API_METHODS.get);
};

export const getComments = (postId) => {
  return fetchAPI(`${API_ROUTES.getComments}${postId}`, API_METHODS.get);
};

export const saveGPTResponse = (
  message,
  questionId,
  username,
  socialNetwork,
  mediaId
) => {
  return fetchAPI(API_ROUTES.saveGPTResponse, API_METHODS.post, {
    message,
    questionId,
    username: encodeURIComponent(username),
    socialNetwork,
    mediaId,
  });
};
