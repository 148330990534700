import { View, Text } from "@react-pdf/renderer";

import { detailedUpper } from "../styles.js";

export const PdfTab = ({ label, i: key, tab }) => {
  const selectedTab = {
    block: detailedUpper.selected_block,
    text: detailedUpper.btn_selected,
  };

  const unselectedTab = {
    block: detailedUpper.unselected_block,
    text: detailedUpper.btn_unselected,
  };

  return (
    <View style={tab === key ? selectedTab.block : unselectedTab.block}>
      <Text style={tab === key ? selectedTab.text : unselectedTab.text}>
        {label}
      </Text>
    </View>
  );
};
