import { Font } from "@react-pdf/renderer";
import PoppinsRegular from "../../fonts/Poppins/Poppins-Regular.ttf";
import FiraCodeRegular from "../../fonts/FiraCode/FiraCode-Regular.ttf";
import Roboto from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoMedium from "../../fonts/Roboto/Roboto-Medium.ttf";

export const registerFonts = () => {
  Font.register({
    family: "Poppins",
    fonts: [
      {
        src: PoppinsRegular,
        fontStyle: "normal",
        fontWeight: "normal",
      },
    ],
  });

  Font.register({
    family: "Fira Code",
    fonts: [
      {
        src: FiraCodeRegular,
        fontStyle: "normal",
        fontWeight: "normal",
      },
    ],
  });
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: Roboto,
        fontStyle: "normal",
        fontWeight: "normal",
      },
    ],
  });
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: RobotoMedium,
        fontStyle: "normal",
        fontWeight: "bold",
      },
    ],
  });
};
