import { useContext } from "react";
import { ProjectContext } from "../../../../contexts/project";
import { Button, ProfileLabel } from "../../../../components";
import { MENU_TABS } from "../../../../constants/detailedProject";
import { NAVIGATION } from "../../../../constants/navigation";
import "./styles.scss";

export const ProjectHeader = ({ handlePrint }) => {
  const projectContext = useContext(ProjectContext);

  const { selectedMenuTab, setSelectedMenuTab, profile } = projectContext;
  return (
    <div className="detailedProject_upper">
      <div className="detailedProject_upper_menuBlock">
        <div>
          <Button
            className="detailedProject_upper_btn_back"
            link={NAVIGATION.project}
          >
            Back
          </Button>
        </div>
        <div className="detailedProject_upper_menuBlock_item">
          {profile && profile.username && profile.socialNetwork && (
            <ProfileLabel
              name={profile.username}
              socialMedia={profile.socialNetwork}
              tooltip={true}
            />
          )}
        </div>
        <div className="detailedProject_upper_btn_wrap">
          <div className="detailedProject_upper_btn">
            <Button
              className={
                selectedMenuTab === MENU_TABS.initialTab
                  ? "detailedProject_upper_btn_selected"
                  : "detailedProject_upper_btn_unselected"
              }
              type={
                selectedMenuTab === MENU_TABS.initialTab
                  ? "secondary"
                  : "primary"
              }
              onClick={() => setSelectedMenuTab(MENU_TABS.initialTab)}
            >
              My selection
            </Button>
          </div>

          <div className="detailedProject_upper_btn">
            <Button
              type={
                selectedMenuTab === MENU_TABS.secondTab
                  ? "secondary"
                  : "primary"
              }
              className={
                selectedMenuTab === MENU_TABS.secondTab
                  ? "detailedProject_upper_btn_selected"
                  : "detailedProject_upper_btn_unselected"
              }
              onClick={() => setSelectedMenuTab(MENU_TABS.secondTab)}
            >
              Unselected notes
            </Button>
          </div>
          <div className="detailedProject_upper_btn">
            <Button
              className="detailedProject_upper_btn_pdf"
              type="secondary"
              onClick={handlePrint}
            >
              Export PDF
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
