import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  ProfileLabel,
  Modal,
  AuditLayout,
  MediaCard,
  Button,
  PostsPDF,
} from "../../components";
import { SlickSlider } from "./components/slider";
import { PostMedia } from "./components/media/postMedia";
import { pdf } from "@react-pdf/renderer";

import { useMessage } from "../../hooks/useMessage";
import { PostsContext } from "../../contexts/posts";
import { NAVIGATION } from "../../constants/navigation";

import "./styles.scss";

export const PostsScreen = () => {
  const postsContext = useContext(PostsContext);
  const [profileData, setProfileData] = useState();
  const [postList, setPostList] = useState([]);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { infoMessage, contextHolder } = useMessage("loading", true);
  const [showGenerateComponent, setShowGenerateComponent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!postsContext.profileData?.media) navigate(NAVIGATION.select);

    if (postsContext.profileData?.media)
      setPostList(postsContext.profileData.media);

    if (postsContext.profileData?.profile)
      setProfileData(postsContext.profileData.profile);
  }, []);

  const savePost = (id) => {
    const sortedList = [...postList];
    sortedList.forEach((item) =>
      item.id === id ? (item.isSaved = !item.isSaved) : item
    );
    setPostList(sortedList);
  };

  const handlePrint = async () => {
    if (postList) {
      setShowGenerateComponent(!showGenerateComponent);
      const myPdf = pdf(<PostsPDF data={profileData} posts={postList} />);
      const blob = await myPdf.toBlob();
      const fileUrl = URL.createObjectURL(blob);
      const docWindow = window.open(fileUrl, "PRINT", "height=720,width=1280");
      docWindow?.focus();
      docWindow?.print();

      setShowGenerateComponent(false);
    }
  };
  return (
    <AuditLayout title="Audit">
      <div className="postsScreen">
        {contextHolder}
        <div className="postsScreen_upper">
          <div className="postsScreen_upper_wrap">
            {profileData && (
              <div className="postsScreen_upper_label">
                <ProfileLabel
                  name={profileData.username}
                  socialMedia={profileData.socialNetwork}
                  tooltip={true}
                />
              </div>
            )}
            <div className="postsScreen_upper_btn_box">
              <div className="postsScreen_upper_btn_wrap">
                <Button
                  className="postsScreen_upper_btn_pdf"
                  onClick={handlePrint}
                >
                  Export to PDF
                </Button>
              </div>
              <div className="postsScreen_upper_btn_wrap">
                <Button
                  className="postsScreen_upper_btn_filter"
                  type="disabled"
                >
                  Filter: Coming Soon
                </Button>
              </div>
              <div className="postsScreen_upper_btn_wrap">
                <Button
                  className="postsScreen_upper_btn_select"
                  type="disabled"
                >
                  Select All
                </Button>
              </div>
              <div className="postsScreen_upper_btn_wrap">
                <Button
                  className="postsScreen_upper_btn_drawing"
                  link={NAVIGATION.project}
                >
                  Add to Drawing Room
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="postsScreen_bottom">
          <div className="postsScreen_bottom_wrapper">
            {postList &&
              postList.map((item, i) => (
                <div className="postsScreen_bottom_post" key={i}>
                  <MediaCard
                    isCheckbox={true}
                    isModalActive={true}
                    post={item}
                    pageId={i}
                    setModalIsActive={setModalIsActive}
                    setCurrentPage={setCurrentPage}
                    savePost={savePost}
                    ratingPosts={postsContext.profileData.rating}
                    profile={postsContext.profileData.profile}
                  >
                    <PostMedia
                      type={item.media_type}
                      media={item.media_url}
                      childrenMedia={item.children}
                    />
                  </MediaCard>
                </div>
              ))}
          </div>
          {postList && (
            <Modal active={modalIsActive} setActive={setModalIsActive}>
              <SlickSlider
                posts={postList}
                setPosts={setPostList}
                currentPage={currentPage}
                savePost={savePost}
                isActive={modalIsActive}
                ratingPosts={postsContext.profileData.rating}
                profile={postsContext.profileData.profile}
              />
            </Modal>
          )}
        </div>
      </div>
    </AuditLayout>
  );
};
