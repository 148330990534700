import { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { pdf } from "@react-pdf/renderer";

import { AuditLayout, DetailedPDF } from "../../components";
import { ProjectNotes } from "./components/projectNotes";
import { ProjectHeader } from "./components/projectHeader";
import { ProjectPosts } from "./components/projectPosts";

import { ProjectContext } from "../../contexts/project";
import { useMessage } from "../../hooks/useMessage";
import { DATA_TYPES } from "../../constants/detailedProject";
import { getComments, getSavedMedia } from "../../api/mediaRequests";

import "./styles.scss";
import { GPT_BUTTONS } from "../../constants/gpt";

const initialGptNotes = {
  creativeStrategy: "",
  creativePlan: "",
  audienceSentiment: "",
  mediaComparison: "",
};

export const DetailedProjectScreen = () => {
  const [searchParams] = useSearchParams();

  const projectContext = useContext(ProjectContext);

  const {
    selectedMenuTab,
    savedPosts,
    selectedNotes,
    unselectedNotes,
    selectedGptNotes,
    unselectedGptNotes,
    gptNotes,
    isRemoved,
    comments,
    setSavedPosts,
    setSelectedNotes,
    setUnselectedNotes,
    setIsModalOpen,
    setIsGptNote,
    setGptPost,
    setGptNotes,
    setSelectedGptNotes,
    setUnselectedGptNotes,
    setCurrentQuestion,
    setComments,
    setPostComments,
    setGptComments,
    setProfile,
  } = projectContext;

  const [loadings, setLoadings] = useState([]);
  const [showGenerateComponent, setShowGenerateComponent] = useState(false);

  const { infoMessage, contextHolder } = useMessage("error");

  const location = useLocation();

  const username = decodeURIComponent(location.pathname.split("/").pop());
  const socialNetwork = searchParams.get("socialNetwork");

  useEffect(() => {
    setProfile({ username, socialNetwork });
  }, [username, socialNetwork]);

  const handlePrint = async () => {
    setShowGenerateComponent(!showGenerateComponent);
    const myPdf = pdf(
      <DetailedPDF
        data={{ username: decodeURIComponent(username), socialNetwork }}
        posts={savedPosts}
        tab={selectedMenuTab}
        selectedNotes={selectedNotes}
        unselectedNotes={unselectedNotes}
        selectedGptNotes={selectedGptNotes}
        unselectedGptNotes={unselectedGptNotes}
        gptNotes={gptNotes}
      />
    );
    const blob = await myPdf.toBlob();
    const fileUrl = URL.createObjectURL(blob);
    const docWindow = window.open(fileUrl, "PRINT", "height=720,width=1280");
    docWindow?.focus();
    docWindow?.print();

    setShowGenerateComponent(false);
  };

  const getCommentsHandler = async (i) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[i] = true;
      return newLoadings;
    });
    const id = Object.keys(selectedNotes)[i];

    const response = await getComments(id);

    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[i] = false;
      return newLoadings;
    });

    if (!response.success) infoMessage("Error while fetching comments");
    else if (!response.data.length) infoMessage("No comments found");

    setComments((prev) => ({ ...prev, [id]: response.data }));
  };

  const getSavedMediaHandler = (data) => {
    setSavedPosts(data);
    const selectedNotesObj = {};
    const unselectedNotesObj = {};

    const selectedGptNotesObj = {};
    const unselectedGptNotesObj = {};

    const selectedGptCommentsObj = {};

    if (data?.data) {
      data?.data[DATA_TYPES.savedMedias]?.forEach((item) => {
        selectedNotesObj[item.media.id] = item.note;

        selectedGptNotesObj[item?.media?.id] = item?.gpt_notes.find(
          (el) => el.questionId === GPT_BUTTONS.recreate
        )?.response?.message;

        selectedGptCommentsObj[item?.media?.id] = item?.gpt_notes.find(
          (el) => el.questionId === GPT_BUTTONS.commentRecreate
        )?.response?.message;
      });

      data?.data[DATA_TYPES.mediaWithNotes]?.forEach((item) => {
        unselectedNotesObj[item.media.id] = item.note;

        unselectedGptNotesObj[item?.media?.id] = item?.gpt_notes.find(
          (el) => el.questionId === GPT_BUTTONS.recreate
        )?.response?.message;
      });

      setSelectedNotes(selectedNotesObj);
      setUnselectedNotes(unselectedNotesObj);
      setSelectedGptNotes(selectedGptNotesObj);
      setUnselectedGptNotes(unselectedGptNotesObj);
      setGptComments(selectedGptCommentsObj);
    }
  };

  const fetchDataHadler = () =>
    getSavedMedia(username, socialNetwork).then((response) => {
      if (!response.success) infoMessage("Error while fetching saved media");
      setGptNotes({ ...initialGptNotes, ...response?.data?.gptNotes });
      getSavedMediaHandler(response);
    });

  const hadleOpenGptNotesModal = (questionId) => {
    setIsGptNote(!!gptNotes[questionId]);
    setCurrentQuestion(questionId);
    setIsModalOpen(true);
  };

  const handleOpenRecreateModal = (postId, questionId, note) => {
    setIsGptNote(!!note);
    setIsModalOpen(true);
    setCurrentQuestion(questionId);
    setGptPost(postId);
  };

  useEffect(() => {
    fetchDataHadler();
  }, [isRemoved]);

  return (
    <AuditLayout title="DRAWING ROOM">
      {contextHolder}
      <div className="detailedProject" id="my-page">
        <div className="detailedProject_container">
          <ProjectHeader handlePrint={handlePrint} />
          <ProjectPosts />
          <ProjectNotes
            hadleOpenNotesModal={hadleOpenGptNotesModal}
            handleOpenRecreateModal={handleOpenRecreateModal}
            getCommentsHandler={getCommentsHandler}
            loadingStatus={loadings}
          />
        </div>
      </div>
    </AuditLayout>
  );
};
