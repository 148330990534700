import { useContext, useEffect, useState } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./styles.scss";

import { NotesNavItem } from "./components/notesNavItem";
import { GPTSection } from "./components/gptSection";
import { NotesSection } from "./components/notesSection";
import { CommentsSection } from "./components/commentsSection";
import { Button } from "../../../../components";
import { GPTModal } from "../gptModal";

import { GPT_BUTTONS as gptButtons } from "../../../../constants/gpt";
import { ProjectContext } from "../../../../contexts/project";
import { saveNotes, saveProjectNotes } from "../../../../api/mediaRequests";
import {
  DATA_TYPES,
  MENU_TABS,
  TEXTAREA_TABS,
} from "../../../../constants/detailedProject";
import { rating } from "../../../../constants/rating";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      color: "#ae9060",
    }}
    spin
  />
);

const NAV_LINKS = {
  posts: "Posts",
  notes: "Notes",
  creativeStrategy: "Creative Strategy",
  creativePlan: "Content Plan",
  audienceSentiment: "Audience Sentiment",
  mediaComparison: "Post Comparison",
};

const GPT_BUTTONS = {
  creativeStrategy: "Creative Strategy",
  creativePlan: "Content Plan",
  audienceSentiment: "Audience Sentiment",
  mediaComparison: "Post Comparison",
};

export const ProjectNotes = ({
  hadleOpenNotesModal,
  handleOpenRecreateModal,
  getCommentsHandler,
  loadingStatus,
}) => {
  const projectContext = useContext(ProjectContext);
  const {
    savedPosts,
    selectedMenuTab,
    unselectedNotes,
    gptNotes,
    lastChangeNotes,
    selectedGptNotes,
    setSelectedGptNotes,
    unselectedGptNotes,
    setUnselectedGptNotes,
    setGptComments,
    profile: { username, socialNetwork },
  } = projectContext;

  const [textareaTab, setTextareaTab] = useState(TEXTAREA_TABS.notes);

  const hasSelectedPosts =
    savedPosts?.data?.[DATA_TYPES.savedMedias]?.length > 0;
  const hasUnselectedPosts =
    savedPosts?.data?.[DATA_TYPES.mediaWithNotes]?.length > 0;

  const isComparable = (key) => {
    if (key === "mediaComparison") {
      const { savedMedias = [] } = savedPosts?.data || {};
      const topLikesMedia = savedMedias.some(
        (item) => item.rating === rating.topLikes
      );
      const lowLikesMedia = savedMedias.some(
        (item) => item.rating === rating.lowLikes
      );

      return topLikesMedia && lowLikesMedia;
    }
    return true;
  };

  const onChangeResponses = (note, id) => {
    if (selectedMenuTab === MENU_TABS.initialTab) {
      setSelectedGptNotes({ ...selectedGptNotes, [id]: note });
    } else {
      setUnselectedGptNotes({ ...unselectedGptNotes, [id]: note });
    }
  };

  const onChangeCommentResponses = (comment, id) => {
    setGptComments((prevState) => ({ ...prevState, [id]: comment }));
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      const value = lastChangeNotes;
      if (value?.length) {
        const [id, note] = Object.entries(value)[0];
        saveNotes(id, note, username, socialNetwork);
      }
      saveProjectNotes(username, socialNetwork);
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [lastChangeNotes, unselectedNotes]);

  const isNotesTab = textareaTab === TEXTAREA_TABS.notes;

  return (
    <div className="projectNotes_body">
      <GPTModal
        username={username}
        socialNetwork={socialNetwork}
        onChangeResponses={
          textareaTab === TEXTAREA_TABS.notes
            ? onChangeResponses
            : onChangeCommentResponses
        }
        textareaTab={textareaTab}
      />
      {savedPosts?.data &&
        savedPosts?.data[DATA_TYPES.savedMedias]?.length > 0 &&
        selectedMenuTab === MENU_TABS.initialTab && (
          <>
            <div className="projectNotes_body_box">
              <div className="projectNotes_body_content">
                <div id="notes" className="projectNotes_plan">
                  <div className="projectNotes_plan_container">
                    <span
                      onClick={() => setTextareaTab(TEXTAREA_TABS.notes)}
                      className={`projectNotes_plan_title${
                        isNotesTab ? "_active" : ""
                      }`}
                    >
                      Project Notes
                    </span>
                    <span className="projectNotes_plan_divider">
                      &nbsp;|&nbsp;
                    </span>
                    <span
                      onClick={() => setTextareaTab(TEXTAREA_TABS.comments)}
                      className={`projectNotes_plan_title${
                        !isNotesTab ? "_active" : ""
                      }`}
                    >
                      Comments
                    </span>
                    <div className="projectNotes_plan_box">
                      {isNotesTab ? (
                        <NotesSection
                          type={DATA_TYPES.savedMedias}
                          handleOpenRecreateModal={handleOpenRecreateModal}
                          profile={{ username, socialNetwork }}
                        />
                      ) : (
                        <CommentsSection
                          getCommentsHandler={getCommentsHandler}
                          onChangeCommentResponses={onChangeCommentResponses}
                          handleOpenRecreateModal={handleOpenRecreateModal}
                          loadingStatus={loadingStatus}
                          profile={{ username, socialNetwork }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="projectNotes_gpt">
                  {Object.entries(GPT_BUTTONS).map(([key, value]) => {
                    const isDisabled = isComparable(key);
                    return (
                      <Button
                        key={key}
                        onClick={() => hadleOpenNotesModal(gptButtons[key])}
                        className={
                          isDisabled
                            ? "projectNotes_gpt_button"
                            : "projectNotes_gpt_button_disabled"
                        }
                        type="primary"
                      >
                        {value}
                      </Button>
                    );
                  })}
                </div>
                {Object.entries(gptNotes).map(([key, value]) => {
                  const isDisabled = isComparable(key);

                  return (
                    <GPTSection
                      key={key}
                      profile={{ username, socialNetwork }}
                      item={{ key, value }}
                      isDisabled={isDisabled}
                      hadleOpenNotesModal={hadleOpenNotesModal}
                    />
                  );
                })}
              </div>
              <div className="projectNotes_body_nav">
                <div className="projectNotes_body_nav_content">
                  {Object.entries(NAV_LINKS).map(([key, value]) => {
                    const isDisabled = isComparable(key);
                    return (
                      <NotesNavItem
                        key={key}
                        item={{ key, value }}
                        isDisabled={isDisabled}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      {savedPosts?.data &&
        savedPosts?.data[DATA_TYPES.mediaWithNotes]?.length > 0 &&
        selectedMenuTab === MENU_TABS.secondTab && (
          <>
            <div className="projectNotes_plan">
              <span className="projectNotes_plan_title">Project Notes</span>
              <div className="projectNotes_plan_box">
                <NotesSection
                  handleOpenRecreateModal={handleOpenRecreateModal}
                  type={DATA_TYPES.mediaWithNotes}
                  profile={{ username, socialNetwork }}
                />
              </div>
            </div>
          </>
        )}
      {!savedPosts ? (
        <Spin indicator={antIcon} />
      ) : (
        <>
          {!hasSelectedPosts && selectedMenuTab === MENU_TABS.initialTab && (
            <span className="projectNotes_notes_notFound">
              No posts selected
            </span>
          )}
          {!hasUnselectedPosts && selectedMenuTab === MENU_TABS.secondTab && (
            <span className="projectNotes_notes_notFound">
              No posts selected
            </span>
          )}
        </>
      )}
    </div>
  );
};
