import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SelectLayout } from "../../components";
import { getCookie, setCookie } from "../../utils/cookie";
import { useMessage } from "../../hooks/useMessage";
import { API_TOKENS } from "../../constants/api";
import { NAVIGATION } from "../../constants/navigation";
import { REFRESH_TOKEN_EXPIRES } from "../../constants/cookieExpires";
import { loginAuth } from "../../api/auth";

import "./styles.scss";

import { GoogleIcon } from "../../img/icons/googleIcon";
import { Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";

export const AuthScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { infoMessage, contextHolder } = useMessage("error");

  const descriptionText = "Today is a step toward creative excellence";

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await loginAuth(email, password);
      if (!response.success) {
        setEmail("");
        setPassword("");
        throw new Error(response.message);
      }

      setCookie(
        API_TOKENS.refresh,
        response.refreshToken,
        REFRESH_TOKEN_EXPIRES
      );
      setCookie(API_TOKENS.access, response.accessToken, REFRESH_TOKEN_EXPIRES);
      localStorage.setItem("userEmail", email);
      const path = localStorage.getItem("path");
      path ? navigate(path) : navigate(NAVIGATION.select);
    } catch (error) {
      infoMessage(error.message);
    }
  };

  const backButtonHandler = () => {
    localStorage.removeItem("path");
    localStorage.removeItem("date");
    localStorage.removeItem("url");
  };

  useEffect(() => {
    if (getCookie(API_TOKENS.refresh)) navigate(NAVIGATION.select);
  }, []);

  return (
    <SelectLayout isProfile={true} text={descriptionText} title={false}>
      {contextHolder}
      <div className="auth">
        <div className="auth_container">
          <Form className="auth_form" name="auth">
            <label className="auth_form_email">
              <p className="auth_form_email_text">Email</p>
              <Input
                className="auth_form_email_input"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>
            <label className="auth_form_password">
              <p className="auth_form_password_text">Password</p>
              <Input.Password
                className="auth_form_password_input"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </label>
            <div className="auth_form_password_forgot">
              <Link className="auth_form_password_forgot_link">
                Forgot Password?
              </Link>
            </div>
            <div className="auth_form_btn_box">
              <button
                type="button"
                onClick={handleLogin}
                className="auth_form_btn_login"
              >
                Sign in
              </button>
              <Link to={NAVIGATION.root} className="auth_form_btn_link">
                <button
                  onClick={backButtonHandler}
                  className="auth_form_btn_back"
                >
                  Back
                </button>
              </Link>
            </div>
          </Form>
          <div className="auth_or">
            <hr className="auth_or_line" />
            <span className="auth_or_text">Or</span>
            <hr className="auth_or_line" />
          </div>
          <div className="auth_oauth">
            <button className="auth_oauth_google">
              <div className="auth_oauth_icon">
                <GoogleIcon />
              </div>
              Coming Soon: Continue with Google
            </button>
          </div>
          <div className="auth_signUp">
            <span className="auth_signUp_text">Don't you have an account?</span>{" "}
            <Link className="auth_signUp_link">Sign up</Link>
          </div>
        </div>
      </div>
    </SelectLayout>
  );
};
