import React, { useContext, useState } from "react";
import { Comment } from "@ant-design/compatible";
import TextArea from "antd/es/input/TextArea";
import { Avatar, Button as AntdButton } from "antd";
import { ProjectContext } from "../../../../../contexts/project";
import UserIcon from "../../../../../img/userIcon.svg";
import { GPT_BUTTONS } from "../../../../../constants/gpt";
import { getComments, saveGPTResponse } from "../../../../../api/mediaRequests";
import { useMessage } from "../../../../../hooks/useMessage";

export const CommentsSection = ({
  getCommentsHandler,
  loadingStatus,
  onChangeCommentResponses,
  handleOpenRecreateModal,
  profile: { username, socialNetwork },
}) => {
  const projectContext = useContext(ProjectContext);
  const { contextHolder, infoMessage } = useMessage("error");

  const { comments, selectedNotes, gptComments, setPostComments } =
    projectContext;

  const [analizeLoading, setAnalizeLoading] = useState([]);

  const toggleAnalysisLoading = (index, bool) => {
    setAnalizeLoading((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = bool;
      return newLoadings;
    });
  };

  const handleAnalysisClick = async (index, id) => {
    toggleAnalysisLoading(index, true);

    if (comments[id] && comments[id].length !== 0) {
      toggleAnalysisLoading(index, false);
      handleOpenRecreateModal(id, GPT_BUTTONS.commentRecreate, gptComments[id]);
      return;
    }

    const commentId = Object.keys(selectedNotes)[index];
    const response = await getComments(commentId);

    setPostComments(response.data);

    if (!response.success) {
      infoMessage("Error while fetching comments");
      toggleAnalysisLoading(index, false);
      return;
    }

    if (!response.data.length) {
      infoMessage("No comments found");
      toggleAnalysisLoading(index, false);
      return;
    }

    toggleAnalysisLoading(index, false);
    handleOpenRecreateModal(id, GPT_BUTTONS.commentRecreate, gptComments[id]);
  };

  return (
    <div className="projectNotes_plan_comments">
      {contextHolder}
      {Object.keys(selectedNotes)?.map((id, i) => {
        return (
          <div key={i} className="projectNotes_plan_comments_container">
            <span className="projectNotes_plan_numbering">{i + 1}.</span>
            <div className="projectNotes_plan_comments_box">
              {comments[id]?.map((comment) => (
                <div
                  className="projectNotes_plan_comments_box_container"
                  key={comment.id}
                >
                  <Comment
                    className="projectNotes_plan_comments_item"
                    author={
                      <p className="projectNotes_plan_comments_author">
                        {comment.username}
                      </p>
                    }
                    avatar={
                      <Avatar
                        src={UserIcon}
                        alt="User"
                        className="projectNotes_plan_comments_icon"
                      />
                    }
                    content={
                      <p className="projectNotes_plan_comments_text">
                        {comment.text}
                      </p>
                    }
                  >
                    {comment.children?.map((child, i) => (
                      <div key={i}>
                        <Comment
                          className="projectNotes_plan_comments_item"
                          author={
                            <p className="projectNotes_plan_comments_text">
                              {child.username}
                            </p>
                          }
                          avatar={
                            <Avatar
                              src={UserIcon}
                              alt="User"
                              className="projectNotes_plan_comments_icon"
                            />
                          }
                          content={
                            <p className="projectNotes_plan_comments_text">
                              {child.text}
                            </p>
                          }
                        />
                      </div>
                    ))}
                  </Comment>
                </div>
              ))}
              {gptComments[id] != undefined && (
                <>
                  <h2 className="projectNotes_plan_title_stars">***</h2>
                  <h2 className="projectNotes_plan_title_text">
                    CREATRIX RESULTS:
                  </h2>
                  <TextArea
                    className="projectNotes_plan_input_recreate"
                    style={{ resize: "none" }}
                    onChange={(e) =>
                      onChangeCommentResponses(e.target.value, id)
                    }
                    bordered={false}
                    value={gptComments[id]}
                    autoSize
                    onBlur={(e) =>
                      saveGPTResponse(
                        e.target.value,
                        GPT_BUTTONS.commentRecreate,
                        username,
                        socialNetwork,
                        id
                      )
                    }
                    onMouseLeave={(e) =>
                      saveGPTResponse(
                        e.target.value,
                        GPT_BUTTONS.commentRecreate,
                        username,
                        socialNetwork,
                        id
                      )
                    }
                  />
                </>
              )}
            </div>

            <div className="projectNotes_plan_comments_btnsBlock">
              <AntdButton
                type="primary"
                className=" projectNotes_plan_comments_btnsBlock_button"
                loading={loadingStatus[i]}
                onClick={() => getCommentsHandler(i)}
              >
                Get comments
              </AntdButton>
              <AntdButton
                type="primary"
                className=" projectNotes_plan_comments_btnsBlock_button"
                loading={analizeLoading[i]}
                onClick={() => handleAnalysisClick(i, id)}
              >
                Analysis
              </AntdButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};
