import { Router } from "./navigation/router";

import "./styles/global.css";
import "./styles/fonts.css";

function App() {
  return <Router />;
}

export default App;
