import { message } from "antd";

export const useMessage = (type, withTimeout) => {
  const key = "updatable";
  const [messageApi, contextHolder] = message.useMessage();
  const infoMessage = (message) => {
    messageApi.open({
      key,
      type,
      content: type ===  "loading" ? "Saving..." : message,
      style: {
        marginTop: "-6px",
      },
    });
    if (withTimeout) {
      setTimeout(() => {
        messageApi.open({
          key,
          type: "success",
          content: "Saved!",
          duration: 2,
          style: {
            marginTop: "-6px",
          },
        });
      }, 1000);
    }
  };
  return {
    infoMessage,
    contextHolder,
  };
};
