import Slider from "react-slick";

import {
  loadingDescription,
  fadeSettings,
} from "../../../constants/loadingSlider";

import "./styles.scss";

export const FadeSlider = () => (
  <Slider {...fadeSettings} className="fadeSlider">
    {loadingDescription.map((item, index) => (
      <div key={index} className="fadeSlider_box">
        <h3 className="fadeSlider_title">{item.title}</h3>
        <p className="fadeSlider_description"> {item.description}</p>
      </div>
    ))}
  </Slider>
);
