export const PrivacyScreen = () => {
  const htmlContent = `
  <!DOCTYPE html>
  <html><head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8">
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)">
	<meta name="created" content="00:00:00">
	<meta name="changed" content="00:00:00">
	<style type="text/css">
		@page { size: 8.5in 11in; margin: 1in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
	</style>
  </head>
  <body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p style="margin-bottom: 0in">
  Privacy Policy</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <p style="margin-bottom: 0in">Last updated: May 2, 2023</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <p style="margin-bottom: 0in">Creatrix Saas Inc ("Creatrix",
  "we", "us", or "our") is committed to
  protecting your privacy. This Privacy Policy explains how we collect,
  use, disclose, and safeguard your information when you visit our
  website or use our software and services (collectively, the
  "Services"). Please read this Privacy Policy carefully. If
  you do not agree with the terms of this Privacy Policy, please do not
  access or use the Services.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <p style="margin-bottom: 0in">We reserve the right to make changes to
  this Privacy Policy at any time and for any reason. We will alert you
  about any changes by updating the "Last updated" date of
  this Privacy Policy. You are encouraged to periodically review this
  Privacy Policy to stay informed of updates. You will be deemed to
  have been made aware of, will be subject to, and will be deemed to
  have accepted the changes in any revised Privacy Policy by your
  continued use of the Services after the date such revised Privacy
  Policy is posted.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol>
    <li><p style="margin-bottom: 0in">Collection of Your Information</p>
  </li></ol>
  <p style="margin-bottom: 0in">We may collect information about you in
  various ways when you use our Services, including:</p>
  <p style="margin-bottom: 0in">a. Personal Information: We collect
  personally identifiable information, such as your name, email
  address, mailing address, phone number, and other personal
  information that you provide to us when you register for an account,
  subscribe to our Services, or otherwise interact with us.</p>
  <p style="margin-bottom: 0in">b. Usage Data: We automatically collect
  certain information when you use our Services, such as your IP
  address, browser type, device type, access times, and pages visited.</p>
  <p style="margin-bottom: 0in">c. Cookies and Tracking Technologies:
  We may use cookies, web beacons, and other tracking technologies to
  collect information about you and your interaction with our Services.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol start="2">
    <li><p style="margin-bottom: 0in">Use of Your Information</p>
  </li></ol>
  <p style="margin-bottom: 0in">We may use the information we collect
  about you for various purposes, including to:</p>
  <p style="margin-bottom: 0in">a. Provide, maintain, and improve our
  Services.</p>
  <p style="margin-bottom: 0in">b. Personalize and improve your user
  experience.</p>
  <p style="margin-bottom: 0in">c. Send you technical notices, updates,
  and support messages.</p>
  <p style="margin-bottom: 0in">d. Respond to your inquiries, comments,
  and feedback.</p>
  <p style="margin-bottom: 0in">e. Monitor and analyze trends, usage,
  and activities in connection with our Services.</p>
  <p style="margin-bottom: 0in">f. Detect, investigate, and prevent
  fraudulent transactions and other illegal activities.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol start="3">
    <li><p style="margin-bottom: 0in">Disclosure of Your Information</p>
  </li></ol>
  <p style="margin-bottom: 0in">We may share your information with
  third parties under certain circumstances, including:</p>
  <p style="margin-bottom: 0in">a. Service Providers: We may share your
  information with vendors and other third-party service providers that
  perform services on our behalf, as needed to carry out their work for
  us, which may include data analysis, payment processing, and customer
  support.</p>
  <p style="margin-bottom: 0in">b. Legal Requirements: We may disclose
  your information as required by law or in response to legal process,
  such as a subpoena, court order, or government request.</p>
  <p style="margin-bottom: 0in">c. Business Transfers: We may disclose
  your information in connection with a merger, acquisition, or sale of
  all or a portion of our assets.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol start="4">
    <li><p style="margin-bottom: 0in">Security of Your Information</p>
  </li></ol>
  <p style="margin-bottom: 0in">We take appropriate security measures
  to protect your information from unauthorized access, use, or
  disclosure. However, no method of transmission over the Internet or
  method of electronic storage is 100% secure. Therefore, while we
  strive to use commercially acceptable means to protect your personal
  information, we cannot guarantee its absolute security.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol start="5">
    <li><p style="margin-bottom: 0in">Children's Privacy</p>
  </li></ol>
  <p style="margin-bottom: 0in">Our Services are not intended for use
  by children under the age of 13. We do not knowingly collect
  personally identifiable information from children under 13. If we
  become aware that we have inadvertently collected personal
  information from a child under the age of 13, we will take steps to
  remove that information from our systems.</p>
  <p style="margin-bottom: 0in"><br>

  </p>
  <ol start="6">
    <li><p style="margin-bottom: 0in">Contact Us</p>
  </li></ol>
  <p style="margin-bottom: 0in">If you have any questions or concerns
  about this Privacy Policy or our privacy practices, please contact us
  at:</p>
  <p style="margin-bottom: 0in">Creatrix Saas Inc</p>
  <p style="margin-bottom: 0in">Attn: Privacy Officer</p>
  <p style="margin-bottom: 0in">Email: lauren@creatrixsaas.com</p>
  <p style="margin-bottom: 0in"><br>

  </p>

  </body></html>
  `;
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
