import { Tooltip } from "antd";
import { sliceString } from "../../utils/sliceString";
import { socialNetworks } from "../../constants/socialNetworks";

import instagramIcon from "../../img/instagramIcon.svg";
import tiktokIcon from "../../img/tiktokIcon.svg";
import hashtagIcon from "../../img/hashtagIcon.svg";

import "./styles.scss";

export const ProfileLabel = ({
  img,
  name,
  socialMedia,
  height,
  width,
  type = "primary",
  tooltip = false,
}) => {
  const formattedName = sliceString(
    name.startsWith("#") ? name : `@${name}`,
    10
  );

  return (
    <Tooltip placement="bottom" title={tooltip ? name : null}>
      {type === "secondary" ? (
        <div className={"label_project hoverable"} style={{ height, width }}>
          {formattedName.startsWith("#") ? (
            <img
              className="label_image_project"
              src={img || hashtagIcon}
              alt="icon"
              width={35}
            />
          ) : (
            <img
              className="label_image_project"
              src={img}
              alt="icon"
              width={35}
            />
          )}
          <div className="label_box">
            <span className="label_title_project">{formattedName}</span>
            <span className="label_text">{socialMedia}</span>
          </div>
        </div>
      ) : (
        <div className="label" style={{ height, width }}>
          {socialMedia === socialNetworks.instagram ? (
            <img
              className="label_image"
              src={instagramIcon}
              alt="icon"
              width={25}
            />
          ) : (
            <img
              className="label_image"
              src={tiktokIcon}
              alt="icon"
              width={25}
            />
          )}
          <div className="label_box">
            <span className="label_title">{formattedName}</span>
          </div>
        </div>
      )}
    </Tooltip>
  );
};
