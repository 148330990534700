export const NotesNavItem = ({ item: { key, value }, isDisabled }) => (
  <a
    href={`#${key}`}
    className={
      isDisabled
        ? "projectNotes_body_nav_link"
        : "projectNotes_body_nav_link_disabled"
    }
    key={key}
  >
    {value}
  </a>
);
