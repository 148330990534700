import { BrowserRouter, Route, Routes } from "react-router-dom";

import { StartScreen } from "../screens/start";
import { SelectScreen } from "../screens/select";
import { HomeScreen } from "../screens/home";

import { PostsScreen } from "../screens/posts";
import { ProjectScreen } from "../screens/project";
import { DetailedProjectScreen } from "../screens/detailedProject";
import { TermsScreen } from "../screens/terms";
import { PrivacyScreen } from "../screens/privacy";
import { AuthScreen } from "../screens/auth";
import { ProtectedRoute } from "./protectedRoute";

import { PostsContextProvider } from "../contexts/posts";
import { ProjectContextProvider } from "../contexts/project";
import { NAVIGATION } from "../constants/navigation";

export const Router = () => (
  <PostsContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path={NAVIGATION.home} element={<HomeScreen />} />
        <Route path={NAVIGATION.auth} element={<AuthScreen />} />

        <Route element={<ProtectedRoute />}>
          <Route path={NAVIGATION.root} element={<StartScreen />} />
          <Route path={NAVIGATION.select} element={<SelectScreen />} />
          <Route path={NAVIGATION.posts} element={<PostsScreen />} />
          <Route path={NAVIGATION.project} element={<ProjectScreen />} />
          <Route
            path={NAVIGATION.detailedProject}
            element={
              <ProjectContextProvider>
                <DetailedProjectScreen />
              </ProjectContextProvider>
            }
          />
        </Route>
        <Route path={NAVIGATION.terms} element={<TermsScreen />} />
        <Route path={NAVIGATION.privacy} element={<PrivacyScreen />} />
      </Routes>
    </BrowserRouter>
  </PostsContextProvider>
);
