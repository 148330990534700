import { useContext, useEffect, useState } from "react";

import { Button, Modal } from "../../../../components";

import {
  getPostGPTResponse,
  getProjectGPTResponse,
  saveGPTResponse,
} from "../../../../api/mediaRequests";
import { ProjectContext } from "../../../../contexts/project";
import { useMessage } from "../../../../hooks/useMessage";
import { GPT_BUTTONS } from "../../../../constants/gpt";

import logo from "../../../../img/creatrixImg.png";
import loadingOctopus from "../../../../img/loadingOctopus.mov";
import { RemoveIcon } from "../../../../img/icons/removeIcon";

import "./styles.scss";
import {
  MENU_TABS,
  TEXTAREA_TABS,
} from "../../../../constants/detailedProject";
import { API_METHODS } from "../../../../constants/api";

const steps = {
  loading: 1,
  results: 2,
  confirm: 3,
};

export const GPTModal = ({
  username,
  socialNetwork,
  onChangeResponses,
  textareaTab,
}) => {
  const [step, setStep] = useState(steps.loading);
  const [gptResponse, setGptResponse] = useState();

  const projectContext = useContext(ProjectContext);

  const {
    setSelectedGptNotes,
    setUnselectedGptNotes,
    setGptNotes,
    setIsModalOpen: setActive,
    selectedMenuTab,
    isModalOpen: active,
    gptPost: postId,
    isGptNote,
    currentQuestion: questionId,
    setGptComments,
    postComments,
  } = projectContext;

  const isCommentsTab = textareaTab === TEXTAREA_TABS.comments;

  const setMediaGptNotes = !isCommentsTab
    ? selectedMenuTab === MENU_TABS.initialTab
      ? setSelectedGptNotes
      : setUnselectedGptNotes
    : setGptComments;

  const { infoMessage, contextHolder } = useMessage("error");
  const onSaveHandler = () => {
    if (gptResponse) {
      let contentId = [username, socialNetwork];
      if (questionId === GPT_BUTTONS.recreate) {
        contentId.push(postId);
        setMediaGptNotes((prev) => ({
          ...prev,
          [postId]: gptResponse.content,
        }));
        onChangeResponses(gptResponse.content, postId);
      } else if (questionId === GPT_BUTTONS.commentRecreate) {
        contentId.push(postId);
        setGptComments((prev) => ({
          ...prev,
          [postId]: gptResponse.content,
        }));
      } else {
        setGptNotes((prev) => ({
          ...prev,
          [questionId]: gptResponse.content,
        }));
      }

      saveGPTResponse(gptResponse.content, questionId, ...contentId).then(
        (response) => {
          if (response.success) {
            setActive(false);

            const element = document.querySelector(`#${questionId}`);
            if (element) element.scrollIntoView({ behavior: "smooth" });
          } else {
            infoMessage("Error while saving response");
          }
        }
      );
    }
  };

  const setData = (response) => {
    if (response.success) {
      setGptResponse(response.data);
      setStep(steps.results);
    } else {
      setActive(false);
      infoMessage(response?.message);
    }
  };

  const getResponseHandler = () => {
    setStep(steps.loading);

    if (questionId === GPT_BUTTONS.recreate) {
      getPostGPTResponse(postId, questionId).then((response) =>
        setData(response)
      );
    } else if (questionId === GPT_BUTTONS.commentRecreate) {
      getPostGPTResponse(
        postId,
        questionId,
        API_METHODS.post,
        postComments
      ).then((response) => setData(response));
    } else {
      getProjectGPTResponse(username, socialNetwork, questionId).then(
        (response) => setData(response)
      );
    }
  };

  useEffect(() => {
    setGptResponse();

    if (active && !isGptNote) getResponseHandler();
    else setStep(steps.confirm);
  }, [active]);

  return (
    <Modal active={active} setActive={setActive}>
      {contextHolder}
      <div className="gptModal">
        <img src={logo} className="gptModal_logo" alt="modal creatrix logo" />
        {step === steps.loading && (
          <div className="gptModal_loading">
            <p>Great choice!</p>
            <p className="gptModal_loading_inProgress">
              Your Results are now in progress...
            </p>
            <video autoPlay loop muted className="gptModal_loading_video">
              <source src={loadingOctopus} type="video/mp4" />
            </video>
            <p>Refill your coffee / drink water!</p>
            <p className="gptModal_loading_description">
              And relax while we us turn these insights into recreations for
              you. Then scroll down to see the results.
            </p>
          </div>
        )}
        {step === steps.results && (
          <div className="gptModal_result">
            <pre className="gptModal_result_text">{gptResponse?.content}</pre>
            <div className="gptModal_result_container">
              <Button onClick={onSaveHandler} className="gptModal_btn">
                Save
              </Button>

              <Button onClick={getResponseHandler} className="gptModal_btn">
                Regenerate
              </Button>

              <div className="gptModal_result_delete">
                <Button
                  onClick={() => setActive(false)}
                  className="gptModal_btn gptModal_btn_delete"
                >
                  <div className=" gptModal_btn_delete_icon">
                    <RemoveIcon width={20} height={20} />
                  </div>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        {step === steps.confirm && (
          <div className="gptModal_confirm">
            <p className="gptModal_confirm_text">
              <strong>WARNING:</strong> If you push recreate, your old result,
              including your edits to the result, will be deleted and replaced
              with the new result. Your original project notes will remain as
              they are.
              <br />
              <br /> Are you sure you want to recreate?
            </p>
            <div className="gptModal_confirm_container">
              <Button onClick={getResponseHandler} className="gptModal_btn">
                Yes
              </Button>
              <Button onClick={() => setActive(false)} className="gptModal_btn">
                No
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
