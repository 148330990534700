import { DatePicker as DateSelect } from "antd";

import "./styles.scss";

export const DatePicker = ({ setValue, isDisabled, value }) => {
  const onChange = (_, dateString) => {
    const startDate = dateString.split(" - ")[0];
    setValue(startDate);
  };

  const dateFormat = `MM-DD-YYYY - ${new Date()
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, "-")}`;

  const isDatePickerDisabled = isDisabled
    ? "datePicker disabled"
    : "datePicker";
  const isDatePickerTitleDisabled = isDisabled
    ? "datePicker_title disabled"
    : "datePicker_title";

  return (
    <div className={isDatePickerDisabled}>
      <span className={isDatePickerTitleDisabled}>Date Range</span>
      <DateSelect
        disabled={isDisabled}
        onChange={onChange}
        allowClear={false}
        suffixIcon={null}
        placeholder={value ? value : "mm/dd/yy"}
        inputReadOnly={true}
        className="ant_body"
        format={dateFormat}
        bordered={false}
      />
    </div>
  );
};
