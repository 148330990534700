export const MENU_ITEMS = [
  {
    key: "1",
    label: "My selection",
  },
  {
    key: "2",
    label: "Unselected Notes",
  },
];

export const DATA_TYPES = {
  savedMedias: "savedMedias",
  mediaWithNotes: "mediaWithNotes",
};

export const MENU_TABS = {
  initialTab: "1",
  secondTab: "2",
};

export const TEXTAREA_TABS = {
  comments: "comments",
  notes: "notes",
};

export const MEDIA_FORMATS = {
  img: "IMAGE",
  vid: "VIDEO",
  carousel: "CAROUSEL_ALBUM",
  carouselTikTok: "CAROUSEL_TIKTOK",
};
