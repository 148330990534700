import { View, Text, Image } from "@react-pdf/renderer";

import {
  MENU_TABS,
  MEDIA_FORMATS,
} from "../../../constants/detailedProject.js";
import { sliceString } from "../../../utils/sliceString.js";

import { HeartIcon } from "../../../img/icons/pdf/heartIcon.jsx";
import { ShareIcon } from "../../../img/icons/pdf/shareIcon.jsx";
import { CommentsIcon } from "../../../img/icons/pdf/commentsIcon.jsx";
import { DownloadIcon } from "../../../img/icons/pdf/downloadIcon.jsx";
import { CoauthorIcon } from "../../../img/icons/pdf/coauthorIcon.jsx";

import { postStyles } from "../styles.js";
import { formatDate, formatNumber } from "../../../utils/formatData.js";

export const DetailedPdfPost = ({ item, i, notes, tab }) => {
  const [selectedNotes, unselectedNotes] = notes;
  const {
    media_url,
    media_type,
    children,
    id,
    comments_count,
    like_count,
    timestamp,
    share_count = "-",
    collect_count = "-",
    download_count = "-",
  } = item.media;
  const videoMedia = () => {
    if (media_type === MEDIA_FORMATS.img) {
      return <Image src={media_url} style={postStyles.postContainer_img} />;
    } else if (media_type === MEDIA_FORMATS.vid) {
      return <View style={postStyles.postContainer_upper_error}></View>;
    } else if (media_type === MEDIA_FORMATS.carousel) {
      return (
        <>
          {children.data[0]?.media_type === MEDIA_FORMATS.img && (
            <Image src={media_url} style={postStyles.postContainer_img} />
          )}
          {children.data[0]?.media_type === MEDIA_FORMATS.vid && (
            <View style={postStyles.postContainer_upper_error}></View>
          )}
        </>
      );
    } else if (media_type === MEDIA_FORMATS.carouselTikTok) {
      return (
        <>
          {children.data[0]?.media_type === MEDIA_FORMATS.img && (
            <Image src={media_url} style={postStyles.postContainer_img} />
          )}
          {children.data[0]?.media_type === MEDIA_FORMATS.vid && (
            <View style={postStyles.postContainer_upper_error}></View>
          )}
        </>
      );
    }
  };

  return (
    <View style={postStyles.postContainer} key={i}>
      <View style={postStyles.postContainer_upper}>{videoMedia()}</View>
      <View style={postStyles.postContainer_bottom}>
        <View style={postStyles.postContainer_bottom_section}>
          <View style={{ justifyContent: "center" }}>
            <Text
              style={[
                postStyles.postContainer_bottom_section_text,
                {
                  fontWeight: "extrabold",
                  textAlign: "center",
                  alignItems: "center",
                },
              ]}
            >
              {formatDate(timestamp)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <HeartIcon />
            <Text
              style={[
                postStyles.postContainer_bottom_section_text,
                { fontWeight: "extrabold" },
              ]}
            >
              {like_count.toLocaleString("en-US")}
            </Text>
          </View>
        </View>
        <View style={postStyles.postContainer_bottom_section}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <CommentsIcon />
            <Text style={postStyles.postContainer_bottom_section_text}>
              {formatNumber(comments_count)}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <DownloadIcon />
            <Text style={postStyles.postContainer_bottom_section_text}>
              {formatNumber(download_count)}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <ShareIcon />
            <Text style={postStyles.postContainer_bottom_section_text}>
              {formatNumber(share_count)}
            </Text>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <CoauthorIcon />
            <Text style={postStyles.postContainer_bottom_section_text}>
              {formatNumber(collect_count)}
            </Text>
          </View>
        </View>
        <View style={postStyles.postContainer_bottom_section}>
          <Text style={postStyles.postContainer_bottom_text}>
            {id && tab === MENU_TABS.initialTab
              ? sliceString(selectedNotes[id], 70)
              : sliceString(unselectedNotes[id], 70)}
          </Text>
        </View>
      </View>
    </View>
  );
};
