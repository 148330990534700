import { createContext, useState } from "react";
import { MENU_TABS } from "../constants/detailedProject";

export const ProjectContext = createContext({
  selectedMenuTab: MENU_TABS.initialTab,
  savedPosts: [],
  selectedNotes: {},
  unselectedNotes: {},
  selectedGptNotes: {},
  unselectedGptNotes: {},
  gptNotes: {},
  showGenerateComponent: false,
  isModalOpen: false,
  isGptNote: false,
  isRemoved: 0,
  comments: [],
  postComments: [],
  gptPost: {},
  currentQuestion: {},
  lastChangeNotes: {},
  gptComments: {},
  profile: {},
  setCurrentQuestion: () => {},
  setSelectedMenuTab: () => {},
  setSavedPosts: () => {},
  setSelectedNotes: () => {},
  setUnselectedNotes: () => {},
  setShowGenerateComponent: () => {},
  setIsModalOpen: () => {},
  setIsGptNote: () => {},
  setIsRemoved: () => {},
  setGptPost: () => {},
  setGptNotes: () => {},
  setSelectedGptNotes: () => {},
  setUnselectedGptNotes: () => {},
  setLastChangeNotes: () => {},
  setComments: () => {},
  setPostComments: () => {},
  setGptComments: () => {},
  setProfile: () => {},
});

export const ProjectContextProvider = ({ children }) => {
  const [selectedMenuTab, setSelectedMenuTab] = useState(MENU_TABS.initialTab);
  const [savedPosts, setSavedPosts] = useState();
  const [selectedNotes, setSelectedNotes] = useState({});
  const [unselectedNotes, setUnselectedNotes] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGptNote, setIsGptNote] = useState(false);
  const [isRemoved, setIsRemoved] = useState(0);
  const [gptPost, setGptPost] = useState();
  const [gptNotes, setGptNotes] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [selectedGptNotes, setSelectedGptNotes] = useState({});
  const [unselectedGptNotes, setUnselectedGptNotes] = useState({});
  const [lastChangeNotes, setLastChangeNotes] = useState({});
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [gptComments, setGptComments] = useState({});
  const [profile, setProfile] = useState({});

  const context = {
    selectedMenuTab,
    savedPosts,
    selectedNotes,
    unselectedNotes,
    selectedGptNotes,
    unselectedGptNotes,
    gptNotes,
    isModalOpen,
    isGptNote,
    isRemoved,
    gptPost,
    profile,
    currentQuestion,
    setCurrentQuestion,
    setSelectedMenuTab,
    setSavedPosts,
    setSelectedNotes,
    setUnselectedNotes,
    setIsModalOpen,
    setIsGptNote,
    setIsRemoved,
    setGptPost,
    setGptNotes,
    setSelectedGptNotes,
    setUnselectedGptNotes,
    lastChangeNotes,
    setLastChangeNotes,
    comments,
    setComments,
    postComments,
    setPostComments,
    gptComments,
    setGptComments,
    setProfile,
  };

  return (
    <ProjectContext.Provider value={context}>
      {children}
    </ProjectContext.Provider>
  );
};
