import { Path, Svg } from "@react-pdf/renderer";

export const ShareIcon = ({ width = 8, height = 8 }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M9.09913 1.9704C9.22342 2.05741 9.28557 2.20657 9.26071 2.35572L8.46522 7.52644C8.45279 7.65074 8.37821 7.75018 8.26634 7.81232C8.16691 7.87447 8.03018 7.8869 7.91831 7.83718L6.43919 7.2157L5.58155 8.13549C5.46968 8.25979 5.29567 8.29708 5.14651 8.23493C4.99736 8.18521 4.89792 8.03606 4.89792 7.86204V6.83038C4.89792 6.78067 4.91035 6.73095 4.94764 6.69366L7.02338 4.41904C7.09796 4.34446 7.09796 4.22016 7.02338 4.14559C6.9488 4.07101 6.82451 4.07101 6.74993 4.14559L4.22672 6.38292L3.12048 5.83601C2.99619 5.77387 2.90918 5.63714 2.90918 5.48799C2.89675 5.35126 2.97133 5.21453 3.10805 5.13996L8.67652 1.95797C8.80082 1.8834 8.97483 1.8834 9.09913 1.9704Z"
      fill="#8C8C8C"
    />
  </Svg>
);
