import "./styles.scss";

export const Modal = ({ active, setActive, children }) => (
  <div
    className={active ? "modal active" : "modal"}
    onClick={() => setActive(false)}
  >
    <div className="modal_close">
      <div className="modal_body" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  </div>
);
