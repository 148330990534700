import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import { DetailedPdfPost } from "./components/detailedPdfPost.jsx";
import { PdfTab } from "./components/pdfTab.jsx";

import { registerFonts } from "./fonts.js";
import {
  DATA_TYPES,
  MENU_ITEMS,
  MENU_TABS,
} from "../../constants/detailedProject.js";
import { socialNetworks } from "../../constants/socialNetworks.js";
import { GPT_LABELS } from "../../constants/gpt.js";
import { sliceString } from "../../utils/sliceString.js";

import {
  auditLayout,
  detailedUpper,
  detailedNotes,
  mainStyles,
  postStyles,
} from "./styles.js";

import { InstagramIcon } from "../../img/icons/pdf/instagramIcon.jsx";
import { TiktokIcon } from "../../img/icons/pdf/tiktokIcon.jsx";
import creatrixImg from "../../img/creatrixImg.png";

registerFonts();

export const DetailedPDF = ({
  data,
  posts,
  tab,
  selectedNotes,
  unselectedNotes,
  selectedGptNotes,
  unselectedGptNotes,
  gptNotes,
}) => {
  const activeTabData =
    tab === MENU_TABS.initialTab
      ? DATA_TYPES.savedMedias
      : DATA_TYPES.mediaWithNotes;

  const activeTabGptNotes =
    tab === MENU_TABS.initialTab ? selectedGptNotes : unselectedGptNotes;

  return (
    <Document>
      <Page
        size="A4"
        key="0"
        style={detailedUpper.pdfPage}
        orientation="landscape"
      >
        <View style={auditLayout.container}>
          <Image src={creatrixImg} style={auditLayout.img} />
          <View style={auditLayout.titleBox}>
            <Text style={auditLayout.title}>DRAWING ROOM</Text>
          </View>
        </View>
        <View style={detailedUpper.upper_block}>
          <View style={detailedUpper.upper_block_left}>
            <View style={detailedUpper.back_block}>
              <Text style={detailedUpper.btn_back}>Back</Text>
            </View>
            <View style={detailedUpper.label_container}>
              {data?.socialNetwork === socialNetworks.instagram ? (
                <InstagramIcon />
              ) : (
                <TiktokIcon />
              )}
              <View style={detailedUpper.label_text_block}>
                <Text style={detailedUpper.label_text}>{`@${sliceString(
                  data?.username,
                  9
                )}`}</Text>
              </View>
            </View>
          </View>
          <View style={detailedUpper.upper_block_right}>
            {MENU_ITEMS.map(({ label, key }) => (
              <PdfTab label={label} key={key} i={key} tab={tab} />
            ))}
            <View style={detailedUpper.pdf_block}>
              <Text style={detailedUpper.btn_pdf}>Export PDF</Text>
            </View>
          </View>
        </View>

        <View style={mainStyles.postsBlock}>
          <View style={mainStyles.postsContainer}>
            {posts?.data[activeTabData].map((post, i) => (
              <DetailedPdfPost
                item={post}
                notes={[selectedNotes, unselectedNotes]}
                tab={tab}
                i={i}
                key={i}
              />
            ))}
          </View>
        </View>

        {posts?.data[activeTabData]?.length > 0 ? (
          <View style={detailedNotes.block}>
            <Text style={detailedNotes.title}>Project Notes</Text>
            <View style={detailedNotes.blockNote}>
              {posts?.data[activeTabData].map((item, i) => (
                <View style={detailedNotes.container} i={i} key={i}>
                  <View style={detailedNotes.noteRow}>
                    <Text style={detailedNotes.numbering}>{i + 1}.</Text>
                    <Text style={detailedNotes.info}>
                      {item.media.id && tab === MENU_TABS.initialTab
                        ? selectedNotes[item.media.id]
                        : unselectedNotes[item.media.id]}
                    </Text>
                  </View>
                  {activeTabGptNotes[item.media.id] && (
                    <View style={detailedNotes.gptNoteColumn}>
                      <Text style={detailedNotes.gptNoteTitle}>***</Text>
                      <Text style={detailedNotes.gptNoteTitle}>
                        CREATRIX RESULTS
                      </Text>
                      <Text style={detailedNotes.info}>
                        {item.media.id && tab === MENU_TABS.initialTab
                          ? selectedGptNotes[item.media.id]
                          : unselectedGptNotes[item.media.id]}
                      </Text>
                    </View>
                  )}
                </View>
              ))}
            </View>
            {tab === MENU_TABS.initialTab &&
              Object.entries(gptNotes).map(([key, value]) => (
                <View style={detailedNotes.container} key={key}>
                  <Text style={detailedNotes.title}>{GPT_LABELS[key]}</Text>
                  <View style={detailedNotes.gptBlockNote}>
                    <Text style={detailedNotes.info}>{value}</Text>
                  </View>
                </View>
              ))}
          </View>
        ) : (
          <Text style={postStyles.not_found}>No posts selected</Text>
        )}
      </Page>
    </Document>
  );
};
