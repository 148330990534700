import { Svg, Rect, Circle } from "@react-pdf/renderer";

export const InstagramIcon = ({ width, height }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="white"
  >
    <Rect
      x="1"
      y="0.997702"
      width="12.1908"
      height="12.1908"
      rx="3"
      ry="3"
      stroke="#AA9165"
      stroke-width="0.993052"
    />
    <Circle
      cx="7.09515"
      cy="7.09309"
      r="2.73089"
      stroke="#AA9165"
      stroke-width="1.24131"
    />
    <Circle cx="10.5704" cy="3.61742" r="0.816519" fill="#AA9165" />
  </Svg>
);
