import { Link } from "react-router-dom";
import { useState } from "react";
import { ProfileLabel } from "../../../components";

export const ProjectItem = ({
  i,
  profile_picture_url,
  socialNetwork,
  username,
  note,
}) => {
  const [projectInfo] = useState({
    username,
    socialNetwork,
    profile_picture_url,
    note,
  });

  const encodedUsername = encodeURIComponent(username);
  return (
    <div className="project_acc_item" key={i}>
      <Link
        state={{ projectInfo: projectInfo }}
        to={`/project/${encodedUsername}?socialNetwork=${socialNetwork}`}
        className="project_acc_link"
      >
        <ProfileLabel
          img={profile_picture_url}
          socialMedia={socialNetwork}
          name={username}
          hoverable={true}
          type={"secondary"}
        />
      </Link>
    </div>
  );
};
