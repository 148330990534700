import { useContext } from "react";
import { ProjectPost } from "../projectPost";
import { ProjectContext } from "../../../../contexts/project";
import { DATA_TYPES, MENU_TABS } from "../../../../constants/detailedProject";

export const ProjectPosts = () => {
  const projectContext = useContext(ProjectContext);
  const {
    selectedNotes,
    unselectedNotes,
    savedPosts,
    selectedMenuTab,
    setIsRemoved,
    profile,
  } = projectContext;
  const renderPosts = (type) => {
    const notes =
      type === DATA_TYPES.savedMedias ? selectedNotes : unselectedNotes;
    return savedPosts?.data?.[type].map((item, i) => {
      if (!notes[item.media.id] && type === "mediaWithNotes") return null;
      return (
        profile && (
          <ProjectPost
            key={item.media.id}
            media={item.media}
            rating={item.rating}
            note={notes[item.media.id]}
            setIsRemoved={setIsRemoved}
            profile={profile}
          />
        )
      );
    });
  };

  return (
    <div className="detailedProject_body">
      {savedPosts?.data?.[DATA_TYPES.savedMedias]?.length > 0 &&
        selectedMenuTab === MENU_TABS.initialTab && (
          <div id="posts" className="detailedProject_notes_container">
            {renderPosts(DATA_TYPES.savedMedias)}
          </div>
        )}
      {savedPosts?.data?.[DATA_TYPES.mediaWithNotes]?.length > 0 &&
        selectedMenuTab === MENU_TABS.secondTab && (
          <div id="posts" className="detailedProject_notes_container">
            {renderPosts(DATA_TYPES.mediaWithNotes)}
          </div>
        )}
    </div>
  );
};
