import { useContext } from "react";
import TextArea from "antd/es/input/TextArea";

import { saveGPTResponse, saveNotes } from "../../../../../api/mediaRequests";
import {
  DATA_TYPES,
  MENU_TABS,
} from "../../../../../constants/detailedProject";
import { ProjectContext } from "../../../../../contexts/project";
import { Button } from "../../../../../components";
import { GPT_BUTTONS, GPT_LABELS } from "../../../../../constants/gpt";

export const NotesSection = ({ type, handleOpenRecreateModal, profile }) => {
  const username = profile?.username;
  const socialNetwork = profile?.socialNetwork;
  const projectContext = useContext(ProjectContext);

  const {
    selectedNotes,
    unselectedNotes,
    selectedGptNotes,
    unselectedGptNotes,
    savedPosts,
    setSelectedNotes,
    setUnselectedNotes,
    selectedMenuTab,
    setSelectedGptNotes,
    setUnselectedGptNotes,
    setLastChangeNotes,
  } = projectContext;

  const onChangeNotes = (e, id) => {
    if (selectedMenuTab === MENU_TABS.initialTab) {
      setSelectedNotes({ ...selectedNotes, [id]: e.target.value });
    } else {
      setUnselectedNotes({ ...unselectedNotes, [id]: e.target.value });
    }
    setLastChangeNotes({ [id]: e.target.value });
  };

  const onChangeResponses = (note, id) => {
    if (selectedMenuTab === MENU_TABS.initialTab) {
      setSelectedGptNotes((prev) => ({ ...prev, [id]: note }));
    } else {
      setUnselectedGptNotes((prev) => ({ ...prev, [id]: note }));
    }
  };

  const notes =
    type === DATA_TYPES.savedMedias ? selectedNotes : unselectedNotes;

  const gptNotes =
    type === DATA_TYPES.savedMedias ? selectedGptNotes : unselectedGptNotes;

  return savedPosts?.data?.[type].map((item, i) => {
    return (
      <div className="projectNotes_plan_info" key={item?.media?.id}>
        <span className="projectNotes_plan_numbering">{i + 1}.</span>
        <div className="projectNotes_plan_input_container">
          <TextArea
            className="projectNotes_plan_input"
            style={{ resize: "none" }}
            onChange={(e) => onChangeNotes(e, item.media.id)}
            bordered={false}
            value={notes[item.media.id]}
            autoSize
            onBlur={(e) =>
              saveNotes(item.media.id, e.target.value, username, socialNetwork)
            }
            onMouseLeave={(e) =>
              saveNotes(item.media.id, e.target.value, username, socialNetwork)
            }
          />
          {gptNotes[item.media.id] !== undefined && (
            <>
              <h2 className="projectNotes_plan_title_stars">***</h2>
              <h2 className="projectNotes_plan_title_text">
                CREATRIX RESULTS:
              </h2>
              <TextArea
                className="projectNotes_plan_input_recreate"
                style={{ resize: "none" }}
                onChange={(e) =>
                  onChangeResponses(e.target.value, item.media.id)
                }
                bordered={false}
                value={gptNotes[item.media.id]}
                autoSize
                onBlur={(e) =>
                  saveGPTResponse(
                    e.target.value,
                    GPT_BUTTONS.recreate,
                    username,
                    socialNetwork,
                    item.media.id
                  )
                }
                onMouseLeave={(e) =>
                  saveGPTResponse(
                    e.target.value,
                    GPT_BUTTONS.recreate,
                    username,
                    socialNetwork,
                    item.media.id
                  )
                }
              />
              {gptNotes[item.media.id] && (
                <Button
                  onClick={() =>
                    navigator.clipboard.writeText(gptNotes[item.media.id])
                  }
                  className="projectNotes_response_button"
                >
                  Copy text
                </Button>
              )}
            </>
          )}
        </div>
        <Button
          className="projectNotes_plan_button"
          onClick={() =>
            handleOpenRecreateModal(
              item.media.id,
              GPT_BUTTONS.recreate,
              !!gptNotes[item.media.id]
            )
          }
        >
          {GPT_LABELS.recreate}
        </Button>
      </div>
    );
  });
};
