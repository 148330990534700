export const CommentsIcon = ({ width = 12, height = 11 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58076 4.87893C8.58076 6.30834 7.15135 7.46429 5.39878 7.46429C4.92645 7.46429 4.49142 7.38972 4.09367 7.25299C3.95694 7.35243 3.70835 7.50158 3.42247 7.62588C3.12416 7.76261 2.7637 7.86204 2.41567 7.86204C2.32866 7.86204 2.25409 7.82475 2.22923 7.75018C2.19194 7.6756 2.2168 7.58859 2.26652 7.52644C2.27894 7.52644 2.27894 7.51401 2.29137 7.50158C2.3038 7.48915 2.32866 7.46429 2.35352 7.43944C2.40324 7.37729 2.46539 7.27785 2.53997 7.16598C2.66426 6.96711 2.77613 6.69366 2.80099 6.38292C2.4281 5.96031 2.2168 5.4507 2.2168 4.87893C2.2168 3.46196 3.63377 2.29357 5.39878 2.29357C7.15135 2.29357 8.58076 3.46196 8.58076 4.87893Z"
      fill="#8C8C8C"
    />
  </svg>
);
