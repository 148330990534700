import "../styles.scss";

export const ErrorMedia = ({ type }) => {
  if (type === "post") {
    return (
      <div className="post_media_error">
        <span className="post_media_error_text">Not available</span>
      </div>
    );
  } else if (type === "slider") {
    return (
      <div className="slider_media_error">
        <span className="slider_media_error_text">Not available</span>
      </div>
    );
  }
};
