import { SelectLayout, Button } from "../../components";
import { LINKS } from "../../constants/links";
import { NAVIGATION } from "../../constants/navigation";

import "./styles.scss";

export const StartScreen = () => (
  <SelectLayout
    isPrivacy={true}
    text="Your all-in-one content marketing generator"
  >
    <div className="startScreen">
      <div className="startScreen_button">
        <Button link={NAVIGATION.home}>Start Audit</Button>
      </div>
      <div className="startScreen_button">
        <Button className="startScreen_button_login" link={NAVIGATION.auth}>
          Login
        </Button>
      </div>
      <div className="startScreen_button">
        <Button
          className="startScreen_button_join"
          target="_blank"
          link={LINKS.waitlistPage}
        >
          Join Waitlist
        </Button>
      </div>
    </div>
  </SelectLayout>
);
