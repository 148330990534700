export const TermsScreen = () => {
  const htmlContent = `
  <html><head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8">
	<title></title>
	<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)">
	<meta name="created" content="00:00:00">
	<meta name="changed" content="00:00:00">
	<style type="text/css">
		@page { size: 8.5in 11in; margin: 1in }
		p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
	</style>
</head>
<body lang="en-US" link="#000080" vlink="#800000" dir="ltr"><p style="margin-bottom: 0in">
Terms of Service</p>
<p style="margin-bottom: 0in"><br>

</p>
<p style="margin-bottom: 0in">Last updated: May 2, 2023</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol>
	<li><p style="margin-bottom: 0in">Agreement to Terms</p>
</li></ol>
<p style="margin-bottom: 0in">These Terms of Service ("Terms")
constitute a legally binding agreement made between you, whether
personally or on behalf of an entity ("you") and Creatrix
Saas Inc, a New York S Corporation ("Creatrix", "we",
"us", or "our"), concerning your access to and
use of the Creatrix website, software, and services (collectively,
the "Services").</p>
<p style="margin-bottom: 0in"><br>

</p>
<p style="margin-bottom: 0in">By accessing or using the Services, you
acknowledge that you have read, understood, and agree to be bound by
these Terms. If you do not agree with these Terms, you must not
access or use the Services.</p>
<p style="margin-bottom: 0in"><br>

</p>
<p style="margin-bottom: 0in">We reserve the right to change, modify,
or remove portions of these Terms at any time. We will alert you
about any changes by updating the "Last updated" date of
these Terms. You are encouraged to periodically review these Terms to
stay informed of updates. Your continued use of the Services after
the posting of revised Terms means that you accept and agree to the
changes.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="2">
	<li><p style="margin-bottom: 0in">Access to the Services</p>
</li></ol>
<p style="margin-bottom: 0in">Subject to these Terms, we grant you a
limited, non-exclusive, non-transferable, non-sublicensable, and
revocable license to access and use the Services for your personal,
non-commercial use, or for your internal business purposes if you are
a legal entity.</p>
<p style="margin-bottom: 0in">You agree not to use the Services for
any illegal or unauthorized purpose, or to engage in any activity
that interferes with the proper working of the Services. You may not
use the Services in any manner that could damage, disable,
overburden, or impair the Services, or in any way that violates these
Terms, applicable laws, or regulations.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="3">
	<li><p style="margin-bottom: 0in">User Accounts</p>
</li></ol>
<p style="margin-bottom: 0in">To use certain features of the
Services, you may be required to create an account with us. You agree
to provide accurate, current, and complete information during the
account registration process and to update such information as
necessary. You are responsible for maintaining the confidentiality of
your account credentials and for any activities that occur under your
account. You agree to notify us immediately of any unauthorized
access or use of your account.</p>
<p style="margin-bottom: 0in">We reserve the right to suspend or
terminate your account, at our sole discretion, if we determine that
you have violated these Terms, provided false information, or engaged
in fraudulent or illegal activities.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="4">
	<li><p style="margin-bottom: 0in">Intellectual Property Rights</p>
</li></ol>
<p style="margin-bottom: 0in">The Services and all content, features,
and functionality, including but not limited to all information,
software, text, displays, images, video, and audio, and the design,
selection, and arrangement thereof, are owned by Creatrix, its
licensors, or other providers of such material and are protected by
United States and international copyright, trademark, patent, trade
secret, and other intellectual property or proprietary rights laws.</p>
<p style="margin-bottom: 0in">You may not copy, reproduce,
distribute, modify, create derivative works of, publicly display,
publicly perform, republish, download, store, or transmit any of the
material on our Services, except as necessary for your own personal,
non-commercial use or for your internal business purposes if you are
a legal entity.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="5">
	<li><p style="margin-bottom: 0in">Third-Party Content</p>
</li></ol>
<p style="margin-bottom: 0in">The Services may contain links to
third-party websites or resources. We are not responsible or liable
for the content, products, or services available from such third
parties. You acknowledge sole responsibility for and assume all risk
arising from your use of any third-party websites or resources.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="6">
	<li><p style="margin-bottom: 0in">Termination</p>
</li></ol>
<p style="margin-bottom: 0in">We may terminate or suspend your access
to the Services, at our sole discretion, without notice or liability,
for any reason, including but not limited to your breach of these
Terms.</p>
<p style="margin-bottom: 0in">Upon termination, your right to access
and use the Services will immediately cease. All provisions of these
Terms, which by their nature should survive termination, shall
survive termination, including but not limited to ownership
provisions, warranty disclaimers, indemnity, and limitations of
liability.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="7">
	<li><p style="margin-bottom: 0in">Disclaimer of Warranties</p>
</li></ol>
<p style="margin-bottom: 0in">The Services are provided on an "as
is" and "as available" basis. Creatrix disclaims all
warranties of any kind, whether express or implied, including but not
limited to the implied warranties of merchantability, fitness for a
particular purpose, and non-infringement. We do not warrant that the
Services will meet your requirements, be uninterrupted, secure, or
error-free, or that defects in the Services will be corrected.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="8">
	<li><p style="margin-bottom: 0in">Limitation of Liability</p>
</li></ol>
<p style="margin-bottom: 0in">To the maximum extent permitted by law,
in no event shall Creatrix, its affiliates, directors, employees, or
agents be liable for any indirect, incidental, special,
consequential, or punitive damages, including but not limited to loss
of profits, data, use, goodwill, or other intangible losses,
resulting from your access to or use of or inability to access or use
the Services; any conduct or content of any third party on the
Services; any content obtained from the Services; or unauthorized
access, use, or alteration of your transmissions or content, whether
based on warranty, contract, tort (including negligence), or any
other legal theory, whether or not we have been informed of the
possibility of such damage, and even if a remedy set forth herein is
found to have failed its essential purpose.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="9">
	<li><p style="margin-bottom: 0in">Indemnification</p>
</li></ol>
<p style="margin-bottom: 0in">You agree to defend, indemnify, and
hold harmless Creatrix, its affiliates, licensors, and service
providers, and its and their respective officers, directors,
employees, contractors, agents, licensors, suppliers, successors, and
assigns from and against any claims, liabilities, damages, judgments,
awards, losses, costs, expenses, or fees (including reasonable
attorneys' fees) arising out of or relating to your violation of
these Terms or your use of the Services, including but not limited to
any use of the Services' content, services, and products other than
as expressly authorized in these Terms, or your use of any
information obtained from the Services.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="10">
	<li><p style="margin-bottom: 0in">Governing Law and Jurisdiction</p>
</li></ol>
<p style="margin-bottom: 0in">These Terms and any dispute or claim
arising out of or related to them, their subject matter, or their
formation (in each case, including non-contractual disputes or
claims) shall be governed by and construed in accordance with the
internal laws of the State of New York without giving effect to any
choice or conflict of law provision or rule.</p>
<p style="margin-bottom: 0in"><br>

</p>
<p style="margin-bottom: 0in">Any legal suit, action, or proceeding
arising out of or related to these Terms or the Services shall be
instituted exclusively in the federal courts of the United States or
the courts of the State of New York, in each case located in the City
of New York and County of New York, although we retain the right to
bring any suit, action, or proceeding against you for breach of these
Terms in your country of residence or any other relevant country. You
waive any and all objections to the exercise of jurisdiction over you
by such courts and to venue in such courts.</p>
<p style="margin-bottom: 0in"><br>

</p>
<ol start="11">
	<li><p style="margin-bottom: 0in">Contact Information</p>
</li></ol>
<p style="margin-bottom: 0in">If you have any questions or concerns
regarding these Terms, please contact us at:</p>
<p style="margin-bottom: 0in">Creatrix Saas Inc</p>
<p style="margin-bottom: 0in">New York, New York</p>
<p style="margin-bottom: 0in">Email: lauren@creatrixsaas.com</p>
<p style="line-height: 100%; margin-top: 0.21in; margin-bottom: 0.21in; border: none; padding: 0in">
<br>
<br>

</p>
<p style="margin-bottom: 0in"><br>

</p>

</body></html>
  `;
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
