import { Popconfirm } from "antd";
import { Checkbox } from "../checkbox";
import { InfoItem } from "./components/InfoItem";
import { removeMedia, saveMedia, saveNotes } from "../../api/mediaRequests";

import { sliceString } from "../../utils/sliceString";
import { formatDate, formatNumber } from "../../utils/formatData";
import { rating as postRating } from "../../constants/rating";

import { HeartIcon } from "../../img/icons/heartIcon";
import { CommentsIcon } from "../../img/icons/commentsIcon";
import { ShareIcon } from "../../img/icons/shareIcon";
import { DownloadIcon } from "../../img/icons/downloadIcon";
import { CoauthorIcon } from "../../img/icons/coauthorIcon";
import { RemoveIcon } from "../../img/icons/removeIcon";
import upArrow from "../../img/upArrow.png";

import "./styles.scss";

export const MediaCard = ({
  children,
  isCheckbox = false,
  isBold = false,
  isModal = false,
  isModalActive = false,
  isRemovable = false,
  post,
  savePost,
  setCurrentPage,
  setModalIsActive,
  setIsRemoved,
  pageId,
  note,
  ratingPosts,
  rating,
  profile,
}) => {
  const {
    isSaved = false,
    comments_count,
    like_count,
    id,
    timestamp,
    share_count = "-",
    collect_count = "-",
    download_count = "-",
  } = post;

  const activateModal = () => {
    setCurrentPage(pageId);
    setModalIsActive(true);
  };

  const confirm = async () => {
    await saveNotes(id, "", profile?.username, profile?.socialNetwork);
    await removeMedia(id, profile?.username, profile?.socialNetwork);

    //id is a random value for updating state that used in useEffect in DetailedProject, here can be any value
    setIsRemoved(id);
  };

  const modalHandler = isModalActive ? activateModal : () => {};

  const iconsSize = isBold ? 26 : 19;

  const infoItems = [
    {
      icon: <CommentsIcon width={iconsSize} height={iconsSize} />,
      description: formatNumber(comments_count),
    },
    {
      icon: <DownloadIcon width={iconsSize} height={iconsSize} />,
      description: formatNumber(download_count),
    },
    {
      icon: <ShareIcon width={iconsSize} height={iconsSize} />,
      description: formatNumber(share_count),
    },
    {
      icon: <CoauthorIcon width={iconsSize} height={iconsSize} />,
      description: formatNumber(collect_count),
    },
  ];

  const postBottomCursor = isModalActive ? {} : { cursor: "default" };
  const postBottomStyle = isModal
    ? {
        height: "100%",
        padding: "12px 12.92px",
        fontSize: 15,
        justifyContent: "space-between",
      }
    : {
        height: "auto",
        padding: "9px 10.9px 0",
        justifyContent: "",
      };

  const boldStyle = { fontSize: isBold ? 15 : 10 };
  const modalMarginDisableStyle = isModal ? { margin: 0 } : {};
  const modalPostStyle = isModal
    ? {
        width: "100%",
        margin: "0 0 2px 0",
        boxShadow: "0px 0 6.46001px 0px rgba(0, 0, 0, 0.25)",
      }
    : null;

  return (
    <div className="post" style={modalPostStyle}>
      <div className="post_upper">
        <div className="post_upper_box">{children}</div>

        {isCheckbox && (
          <div className="post_upper_checkbox">
            <Checkbox
              onChange={() => {
                savePost(id);
                if (isSaved) removeMedia(id, profile?.username, profile?.socialNetwork);
                else saveMedia(id, profile?.username, profile?.socialNetwork, ratingPosts);
              }}
              id={pageId}
              isChecked={isSaved}
              borderRadius={5}
            />
          </div>
        )}
        {isRemovable && (
          <>
            <Popconfirm
              title="Delete the post"
              description="Are you sure to delete this post?"
              onConfirm={confirm}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <div className="post_upper_remove">
                <RemoveIcon width={30} height={30} />
              </div>
            </Popconfirm>
            <div className="post_upper_top">
              <img
                className="post_upper_top_arrow"
                src={upArrow}
                alt="post_upper_top_arrow"
                style={
                  rating === postRating.topLikes
                    ? { transform: "rotate(0deg)" }
                    : { transform: "rotate(180deg)" }
                }
              />
            </div>
          </>
        )}
      </div>
      <div
        className="post_bottom"
        onClick={modalHandler}
        style={{ ...postBottomStyle, ...boldStyle, ...postBottomCursor }}
      >
        <div
          className="post_bottom_box post_bottom_box_bold"
          style={modalMarginDisableStyle}
        >
          <div className="post_item">
            <span className="post_item_description">
              {formatDate(timestamp)}
            </span>
          </div>
          <div className="post_item">
            <div className="post_item_icon">
              <HeartIcon width={iconsSize} height={iconsSize} />
            </div>
            <span className="post_item_description">
              {like_count.toLocaleString("en-US")}
            </span>
          </div>
        </div>
        <div
          className="post_bottom_box post_bottom_box_light"
          style={modalMarginDisableStyle}
        >
          {infoItems.map((item, i) => (
            <InfoItem key={i} icon={item.icon} description={item.description} />
          ))}
        </div>
        {note && (
          <div className="post_bottom_note">
            <span className="post_bottom_note_text">
              {sliceString(note, 70)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
