import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Input, Radio } from "antd";

import { DatePicker, SelectLayout, Button } from "../../components";
import { PostsContext } from "../../contexts/posts";
import { useMessage } from "../../hooks/useMessage";
import { getInstagramPosts } from "../../api/instagram";
import { API_TOKENS } from "../../constants/api";
import { NAVIGATION } from "../../constants/navigation";
import { getCookie } from "../../utils/cookie";
import { rating as postRating } from "../../constants/rating";

import "./styles.scss";

import loadingOctopus from "../../img/loadingOctopus.mov";
import { FadeSlider } from "./components/fadeSlider";

const delay = 8000;

export const HomeScreen = () => {
  const { setProfileData, setDate } = useContext(PostsContext);
  const navigate = useNavigate();
  const { infoMessage, contextHolder } = useMessage("error");
  const [ratingPosts, setRatingPosts] = useState(postRating.topLikes);
  const [dateLimit, setDateLimit] = useState("");
  const [url, setUrl] = useState("");
  const [onSubmit, setSubmit] = useState(false);
  const descriptionText = onSubmit
    ? ""
    : "Enter the account URL to begin auditing";
  const clearInputHandler = (message) => {
    infoMessage(message);
    setSubmit(false);
    setUrl("");
    setDateLimit("");
  };

  const onChange = (e) => {
    setRatingPosts(e.target.value);
  };

  const getInstagramPostsHandler = async () => {
    if (!getCookie(API_TOKENS.refresh)) {
      localStorage.setItem("path", NAVIGATION.home);
      localStorage.setItem("date", dateLimit);
      localStorage.setItem("url", url);
      return navigate(NAVIGATION.auth);
    }
    const startTime = Date.now();
    try {
      const result = await getInstagramPosts(
        url,
        dateLimit.replace(" ", "T"),
        ratingPosts,
        clearInputHandler
      );
      if (!result) return;
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      if (responseTime < delay) {
        setTimeout(() => {
          setProfileData(result);
          setDate(dateLimit);
          navigate(NAVIGATION.posts);
        }, delay - responseTime);
      } else {
        setProfileData(result);
        setDate(dateLimit);
        navigate(NAVIGATION.posts);
      }
    } catch (error) {
      infoMessage(error);
    }
  };

  const restoreFromLocalStorage = () => {
    if (getCookie(API_TOKENS.refresh)) {
      setDateLimit(localStorage.getItem("date"));
      setUrl(localStorage.getItem("url"));
      setSubmit(true);
    }
    localStorage.removeItem("date");
    localStorage.removeItem("url");
  };

  useEffect(() => {
    if (localStorage.getItem("url")) restoreFromLocalStorage();
  }, []);

  useEffect(() => {
    if (url) {
      getInstagramPostsHandler();
    }
  }, [onSubmit]);

  return (
    <SelectLayout
      text={descriptionText}
      title={true}
      isPrivacy={!onSubmit}
      isProfile={true}
      onSubmit={onSubmit}
    >
      {contextHolder}
      <div className="homeScreen">
        {!onSubmit ? (
          <div className="homeScreen_form">
            <div className="homeScreen_form_url">
              <span className="homeScreen_form_url_title">Profile url</span>
              <Input
                className="homeScreen_form_url_input"
                onChange={(e) => setUrl(e.target ? e.target.value : "")}
                value={url}
                placeholder="www.instagram.com"
              />
            </div>
            <div className="homeScreen_form_datePicker">
              <DatePicker setValue={setDateLimit} />
            </div>
            <div className="homeScreen_form_btn_box">
              <div className="homeScreen_form_btn_audit">
                <Button
                  type={url ? "primary" : "disabled"}
                  onClick={() => {
                    setSubmit(true);
                  }}
                >
                  Begin Audit
                </Button>
              </div>
              <div className="homeScreen_form_radio">
                <Radio.Group
                  onChange={onChange}
                  className="homeScreen_form_radio_group"
                  defaultValue={postRating.topLikes}
                  buttonStyle="solid"
                >
                  <Radio.Button
                    className="homeScreen_form_radio_btn"
                    value={postRating.topLikes}
                  >
                    Top Posts
                  </Radio.Button>
                  <Radio.Button
                    className="homeScreen_form_radio_btn"
                    value={postRating.lowLikes}
                  >
                    Lowest Posts
                  </Radio.Button>
                </Radio.Group>
              </div>

              {getCookie(API_TOKENS.refresh) ? (
                <div className="homeScreen_form_btn">
                  <Button
                    className="homeScreen_form_btn_back"
                    link={NAVIGATION.project}
                  >
                    Saved Projects
                  </Button>
                </div>
              ) : (
                <div className="homeScreen_form_btn">
                  <Button
                    className="homeScreen_form_btn_back"
                    link={NAVIGATION.root}
                  >
                    Back
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="homeScreen_loading">
            <span className="homeScreen_loading_title">
              Thanks for the URL - we got it!
            </span>
            <span className="homeScreen_loading_description">
              Your Creatrix Audit is now in Progress…
            </span>
            <video autoPlay loop muted className="homeScreen_loading_video">
              <source src={loadingOctopus} type="video/mp4" />
            </video>
            <FadeSlider />
          </div>
        )}
      </div>
    </SelectLayout>
  );
};
