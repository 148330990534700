import { createContext, useState } from "react";

export const PostsContext = createContext({
  profileData: [],
  setProfileData: (list) => {},
  setDate: () => {},
  date: "",
});

export const PostsContextProvider = ({ children }) => {
  const [profileData, setProfileData] = useState([]);
  const [date, setDate] = useState([]);

  const context = { profileData, setProfileData, setDate, date };

  return (
    <PostsContext.Provider value={context}>{children}</PostsContext.Provider>
  );
};
