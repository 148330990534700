import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import { PdfPost } from "./components/pdfPost.jsx";

import { registerFonts } from "./fonts.js";

import { socialNetworks } from "../../constants/socialNetworks.js";

import { auditLayout, detailedUpper, mainStyles } from "./styles.js";

import { InstagramIcon } from "../../img/icons/pdf/instagramIcon.jsx";
import { TiktokIcon } from "../../img/icons/pdf/tiktokIcon.jsx";

import creatrixImg from "../../img/creatrixImg.png";

import { sliceString } from "../../utils/sliceString.js";

registerFonts();

export const PostsPDF = ({ data, posts }) => {
  return (
    <Document>
      <Page
        size="A4"
        key="0"
        style={detailedUpper.pdfPage}
        orientation="landscape"
      >
        <View style={auditLayout.container}>
          <Image src={creatrixImg} style={auditLayout.img} />
          <View style={auditLayout.titleBox}>
            <Text style={auditLayout.title}>AUDIT</Text>
          </View>
        </View>
        <View style={detailedUpper.upper_block}>
          <View style={detailedUpper.upper_block_left}>
            <View style={detailedUpper.label_container}>
              {data?.socialNetwork === socialNetworks.instagram ? (
                <InstagramIcon />
              ) : (
                <TiktokIcon />
              )}
              <View style={detailedUpper.label_text_block}>
                <Text style={detailedUpper.label_text}>{`@${sliceString(
                  data?.username,
                  9
                )}`}</Text>
              </View>
            </View>
          </View>
          <View style={detailedUpper.upper_block_right}>
            <View style={detailedUpper.pdf_block}>
              <Text style={detailedUpper.btn_pdf}>Export PDF</Text>
            </View>
            <View style={detailedUpper.pdf_block}>
              <Text style={detailedUpper.btn_pdf}>Add to Drawing Room</Text>
            </View>
          </View>
        </View>

        <View style={mainStyles.postsBlock}>
          <View style={mainStyles.postsContainer}>
            {posts?.map((post, i) => (
              <PdfPost item={post} i={i} key={i} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};
