export const GPT_BUTTONS = {
  recreate: "recreate",
  commentRecreate: "commentsRecreate",
  creativePlan: "creativePlan",
  creativeStrategy: "creativeStrategy",
  audienceSentiment: "audienceSentiment",
  mediaComparison: "mediaComparison",
};

export const GPT_LABELS = {
  recreate: "Create",
  creativeStrategy: "Creative Strategy",
  creativePlan: "Content Plan",
  audienceSentiment: "Audience Sentiment",
  mediaComparison: "Post Comparison",
};
