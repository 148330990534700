import { useState } from "react";

import { ErrorMedia } from "./errorMedia";
import { MEDIA_FORMATS } from "../../../../constants/detailedProject";

import "../styles.scss";

export const PostMedia = ({ type, media, childrenMedia }) => {
  const [isError, setIsError] = useState(false);

  if (!media || isError) return <ErrorMedia type={"post"} />;

  return (
    <>
      {type === MEDIA_FORMATS.img && media && (
        <img
          src={media}
          alt="postImage"
          className="post_upper_box_img"
          crossOrigin="anonymous"
        />
      )}
      {type === MEDIA_FORMATS.vid && media && (
        <video
          alt="postImage"
          className="post_upper_box_video"
          controls
          onError={() => setIsError(true)}
        >
          <source src={media} />
        </video>
      )}
      {type === MEDIA_FORMATS.carousel && media && (
        <>
          {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.img && (
            <img
              src={childrenMedia?.data[0]?.media_url}
              alt="postImage"
              className="post_upper_box_img"
            />
          )}
          {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.vid && (
            <video
              alt="postImage"
              className="post_upper_box_video"
              controls
              onError={() => setIsError(true)}
            >
              <source src={childrenMedia?.data[0]?.media_url} />
            </video>
          )}
        </>
      )}

      {type === MEDIA_FORMATS.carouselTikTok && childrenMedia && (
        <>
          {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.img && (
            <img
              src={childrenMedia?.data[0]?.media_url}
              alt="postImage"
              className="post_upper_box_img"
            />
          )}
          {childrenMedia?.data[0]?.media_type === MEDIA_FORMATS.vid && (
            <video
              alt="postImage"
              className="post_upper_box_video"
              controls
              onError={() => setIsError(true)}
            >
              <source src={childrenMedia?.data[0]?.media_url} />
            </video>
          )}
        </>
      )}
    </>
  );
};
