import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { ProjectItem } from "./components/projectItem";
import { Button, NavigationLogo } from "../../components";
import { getSavedProjects } from "../../api/mediaRequests";
import { socialNetworks } from "../../constants/socialNetworks";

import "./styles.scss";

import tiktokIcon from "../../img/tiktokIcon.png";
import youtubeIcon from "../../img/youtubeIcon.png";
import instagramIcon from "../../img/instagramIcon.png";
import { NAVIGATION } from "../../constants/navigation";
import { UserAccount } from "../../components/userAccount";

const colorDarkBegie = "#ae9060";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      color: colorDarkBegie,
    }}
    spin
  />
);

export const ProjectScreen = () => {
  const [projects, setProjects] = useState();

  useEffect(() => {
    const getProjects = async () => {
      const data = await getSavedProjects();
      setProjects(data);
    };
    if (!projects) getProjects();
  }, []);

  const sortProjectsBySocialNetwork = () => {
    const sortedProjects = {
      instagram: [],
      youtube: [],
      tiktok: [],
    };

    if (!projects || !projects.data || projects.data.length === 0)
      return sortedProjects;

    projects.data.forEach((item) => {
      if (item.socialNetwork === socialNetworks.instagram)
        sortedProjects.instagram.push(item);
      if (item.socialNetwork === socialNetworks.tiktok)
        sortedProjects.tiktok.push(item);
      if (item.socialNetwork === socialNetworks.youtube)
        sortedProjects.youtube.push(item);
    });

    return sortedProjects;
  };

  const renderSavedProjects = (socialNetwork) => {
    const data = sortProjectsBySocialNetwork();

    if (!projects || !data[socialNetwork]) return null;

    return data[socialNetwork].map((item, i) => (
      <ProjectItem
        socialNetwork={item.socialNetwork}
        username={item.username}
        profile_picture_url={item.profile_picture_url}
        id={item.id}
        note={item.note}
        key={i}
        i={i}
      />
    ));
  };

  return (
    <div className="project">
      <div className="project_public_box">
        <div className="project_public_column">
          <span className="project_public_title">Saved Projects</span>
          <div className="project_public_link">
            <Button
              type="secondary"
              className="project_public_btn"
              link={NAVIGATION.select}
            >
              Public Audit
            </Button>
          </div>
        </div>

        <div className="project_public_wrapper">
          <NavigationLogo className="project_public_img" />
          <UserAccount />
        </div>
      </div>
      <div className="project_acc_container">
        <div className="project_acc_wrapper">
          {projects ? (
            <>
              <div className="project_acc_column">
                <img
                  src={instagramIcon}
                  alt=""
                  className="project_acc_img circle"
                />
                {renderSavedProjects(socialNetworks.instagram)}
              </div>
              <div className="project_acc_column">
                <img src={tiktokIcon} alt="" className="project_acc_img" />
                {renderSavedProjects(socialNetworks.tiktok)}
              </div>
              <div className="project_acc_column">
                <img
                  src={youtubeIcon}
                  alt=""
                  className="project_acc_img circle"
                />
                <span className="project_acc_soon">Coming soon...</span>
              </div>
            </>
          ) : (
            <div className="project_acc_loader">
              <Spin indicator={antIcon} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
