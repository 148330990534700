import { Link } from "react-router-dom";
import "./styles.scss";

export const Button = ({
  type = "primary",
  className = "",
  link = false,
  target = "_self",
  style = {},
  onClick,
  children,
}) => {
  const buttonFunc = type === "disabled" ? () => {} : onClick;
  const linkFunc = type === "disabled" ? () => {} : link;

  return (
    <>
      {link ? (
        <Link to={linkFunc} target={target} className="share_btn_link">
          <button
            style={style}
            onClick={buttonFunc}
            className={`share_btn_${type} ${className}`}
          >
            {children}
          </button>
        </Link>
      ) : (
        <button
          style={style}
          onClick={buttonFunc}
          className={`share_btn_${type} ${className}`}
        >
          {children}
        </button>
      )}
    </>
  );
};
