export const RightArrow = ({width = 12, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrow - Right">
      <path
        id="Arrow"
        d="M11.1946 0.643369C11.5541 1.00295 11.5868 1.56563 11.2926 1.96213L11.1946 2.07573L2.45811 10.8126L11.1946 19.5495C11.5541 19.9091 11.5868 20.4718 11.2926 20.8683L11.1946 20.9819C10.835 21.3415 10.2723 21.3742 9.8758 21.08L9.7622 20.9819L0.309118 11.5288C-0.0504589 11.1692 -0.083148 10.6066 0.211051 10.21L0.309118 10.0965L9.7622 0.643369C10.1577 0.247834 10.799 0.247834 11.1946 0.643369Z"
        fill="#757575"
      />
    </g>
  </svg>
);
