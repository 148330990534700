import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getCookie } from "../utils/cookie";
import { API_TOKENS } from "../constants/api";
import { NAVIGATION } from "../constants/navigation";

export const ProtectedRoute = () => {
  const location = useLocation();
  const refreshToken = getCookie(API_TOKENS.refresh);

  const path = location.pathname.includes("project") ? NAVIGATION.project : NAVIGATION.select;

  if (!refreshToken && location.pathname !== NAVIGATION.root) {
    localStorage.setItem("path", path);
    return <Navigate to={NAVIGATION.auth} replace={true} />;
  } else if (location.pathname === NAVIGATION.root && refreshToken) {
    return <Navigate to={path} replace={true} />;
  }

  return <Outlet />;
};