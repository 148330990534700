import { useEffect, useState } from "react";
import { Input } from "antd";

import { Media } from "./media/slideMedia";
import { removeMedia, saveMedia, saveNotes } from "../../../api/mediaRequests";
import { Button, Checkbox, MediaCard } from "../../../components";
import { rating as postRating } from "../../../constants/rating";
// import { rating as postRating } from "../../constants/rating";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { generateDefaultValue } from "../../../utils/defaultPostData";

import upArrow from "../../../img/upArrow.png";

const { TextArea } = Input;
export const SlickSliderItem = ({
  posts,
  setPosts,
  item,
  videoRefs,
  savePost,
  rating,
  profile,
  i,
}) => {
  const [checkNotes, setCheckNotes] = useState(!!item.note);
  const [postInfo, setPostInfo] = useState({});
  const [lastChangeNotes, setLastChangeNotes] = useState({});
  const onChangeNotes = (e, id, index) => {
    setLastChangeNotes({ [id]: e.target.value });
    setCheckNotes(!!e.target.value);
    setPosts((oldPosts) => {
      const posts = [...oldPosts];
      posts[index].note = e.target.value;
      return posts;
    });
  };

  useEffect(() => {
    setPostInfo({});
  }, []);

  useEffect(() => {
    const handleTabClose = (event) => {
      if (lastChangeNotes.length) {
        const [id, note] = Object.entries(lastChangeNotes)[0];
        const { username, socialNetwork } = profile;
        saveNotes(id, note, username, socialNetwork);
      }

      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [lastChangeNotes]);
  return (
    <>
      {item && posts && (
        <div className="slider_content">
          <div className="slider_upper">
            <div className="slider_upper_box">
              <MediaCard isModal={true} isBold={true} post={item} profile={profile}>
                <Media
                  type={item.media_type}
                  url={item.media_url}
                  i={i}
                  videoRefs={videoRefs}
                  posts={posts}
                />
              </MediaCard>
            </div>
            <div className="slider_notes_container">
              <div className="slider_notes_postInfo">
                <div className="slider_notes_postInfo_wrap">
                  <img
                    src={item.owner.avatar}
                    alt="avatar"
                    className="slider_notes_postInfo_avatar"
                  />
                  <div className="slider_notes_postInfo_items">
                    <div className="slider_notes_postInfo_container">
                      {item.username !== item.owner.username ? (
                        <>
                          <span className="slider_notes_postInfo_username">
                            {item.owner.username}
                          </span>
                          <span className="slider_notes_postInfo_collab">
                            &
                          </span>
                          <span className="slider_notes_postInfo_username">
                            {item.username}
                          </span>
                        </>
                      ) : (
                        <span className="slider_notes_postInfo_username">
                          {item.username}
                        </span>
                      )}
                    </div>
                    {item.music_info && (
                      <div className="slider_notes_postInfo_music">
                        <>
                          <a
                            href={item.music_info.url}
                            target="_blank"
                            rel="noreferrer"
                            className="slider_notes_postInfo_music"
                          >
                            {item.music_info.artists && (
                              <>
                                <p style={{ marginRight: "2px" }}>
                                  {item.music_info.artists}
                                </p>
                                <span style={{ marginRight: "2px" }}>•</span>
                              </>
                            )}
                            <p>{item.music_info.title}</p>
                          </a>
                        </>
                      </div>
                    )}
                  </div>
                </div>
                <div className="slider_notes_postInfo_top">
                  <img
                    className="slider_notes_postInfo_top_arrow"
                    src={upArrow}
                    alt="slider_notes_postInfo"
                    style={
                      rating === postRating.topLikes
                        ? { transform: "rotate(0deg)" }
                        : { transform: "rotate(180deg)" }
                    }
                  />
                </div>
              </div>
              <div className="slider_notes_info_box">
                <TextArea
                  className="slider_notes_info_text"
                  style={{ height: "200px", resize: "none" }}
                  onChange={(e) => onChangeNotes(e, item.id, i)}
                  placeholder="Enter notes here..."
                  bordered={false}
                  defaultValue={generateDefaultValue(item)}
                  onBlur={(e) =>
                    saveNotes(
                      item.id,
                      e.target.value,
                      profile.username,
                      profile.socialNetwork,
                      rating
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="slider_bottom">
            <div className="slider_bottom_box">
              <div className="slider_bottom_post">
                <div className="slider_bottom_post_wrap">
                  <Button
                    type="secondary"
                    target="_blank"
                    link={item.permalink}
                    className="slider_bottom_post_btn"
                  >
                    <span>Post</span>
                    <span>[link]</span>
                  </Button>
                </div>
              </div>

              <div className="slider_plan_add">
                <div className="slider_plan_title">Add To My Plan</div>
                <div className="slider_plan_check">
                  <Checkbox
                    borderRadius={5}
                    id={i}
                    onChange={() => {
                      savePost(item.id);
                      if (!item.isSaved)
                        removeMedia(
                          item.id,
                          profile.username,
                          profile.socialNetwork
                        );
                      else
                        saveMedia(
                          item.id,
                          profile.username,
                          profile.socialNetwork,
                          rating
                        );
                    }}
                    markerColor="#AE9060"
                    markerSize="2"
                    isChecked={item.isSaved}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
