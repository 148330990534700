import { Path, Svg } from "@react-pdf/renderer";

export const CoauthorIcon = ({ width = 8, height = 8 }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M6.04009 5.07781C5.46833 5.07781 4.94629 4.7795 4.66041 4.28231C4.37452 3.79756 4.37452 3.18851 4.66041 2.69132C4.94629 2.20657 5.46833 1.89583 6.04009 1.89583C6.59943 1.89583 7.12147 2.20657 7.40735 2.69132C7.69323 3.18851 7.69323 3.79756 7.40735 4.28231C7.12147 4.7795 6.59943 5.07781 6.04009 5.07781ZM5.46833 5.67443H6.59943C7.82996 5.67443 8.82433 6.6688 8.82433 7.89933C8.82433 8.0982 8.65031 8.25979 8.45144 8.25979H3.61632C3.41744 8.25979 3.25586 8.0982 3.25586 7.89933C3.25586 6.6688 4.2378 5.67443 5.46833 5.67443Z"
      fill="#8C8C8C"
    />
  </Svg>
);
