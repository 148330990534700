const apiUrl = process.env.REACT_APP_API_URL || "http://localhost";

export const API_URL = `${apiUrl}/api`;

export const API_METHODS = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export const API_ROUTES = {
  saveNotes: "/project/instagram/note",
  saveProjectNotes: "/project/note",
  removeMedia: "/project/instagram/remove",
  saveMedia: "/project/instagram/save",
  getSavedMediaSocial: "?socialNetwork=",
  getSavedMedia: "/project/saved/",
  getParsedLinkInfo: "/project/parseLink?link=",
  getSavedProjects: "/project",
  getProject: "/project/",
  getGPTResponse: "/gpt/ask/",
  removeGPTResponse: "/gpt/responses/",
  getGPTQuestions: "/gpt/questions",
  saveGPTResponse: "/gpt/save",
  getComments: "/project/comments/",
};

export const API_TOKENS = {
  refresh: "refreshToken",
  access: "accessToken",
};
