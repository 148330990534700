export const fadeSettings = {
  autoplay: true,
  dots: false,
  arrows: false,
  swipe: false,
  infinite: false,
  fade: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  pauseOnHover: false,
};

export const loadingDescription = [
  {
    title: " Accessing Analytics",
    description:
      "Connecting to the social media platform's API to gather post-engagement metrics...",
  },
  {
    title: "Compiling Data",
    description:
      "Organizing the likes, comments, saves, shares, and follower interactions into the framework...",
  },
  {
    title: "Sorting Posts",
    description:
      "Applying multi-parameter sorting algorithms to arrange the posts based on the number of likes...",
  },
  {
    title: "Drafting Your Top 10 Posts Into A Visual Audit",
    description:
      "Presenting your top 10 popular posts. This snapshot reveals key insights into what your audience loves most...",
  },
];
