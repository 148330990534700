import { useContext } from "react";

import { ProjectContext } from "../../../../../contexts/project";
import { saveGPTResponse } from "../../../../../api/mediaRequests";
import { GPT_BUTTONS, GPT_LABELS } from "../../../../../constants/gpt";
import { Button } from "../../../../../components";
import TextArea from "antd/es/input/TextArea";

export const GPTSection = ({
  item: { key, value },
  profile: { username, socialNetwork },
  isDisabled,
  hadleOpenNotesModal,
}) => {
  const projectContext = useContext(ProjectContext);
  return (
    <div
      key={key}
      id={key}
      className={
        isDisabled ? "projectNotes_response" : "projectNotes_response_disabled"
      }
    >
      <div className="projectNotes_response_upper">
        <span className="projectNotes_response_title">{GPT_LABELS[key]}</span>
        <Button
          key={key}
          onClick={() => hadleOpenNotesModal(GPT_BUTTONS[key])}
          className="projectNotes_response_button projectNotes_response_button_recreate"
        >
          {GPT_LABELS.recreate}
        </Button>
      </div>
      <div className="projectNotes_response_box">
        <TextArea
          className="projectNotes_response_input"
          style={{ resize: "none" }}
          bordered={false}
          value={value}
          placeholder={`Here will be ${GPT_LABELS[key]}`}
          onChange={(e) =>
            projectContext.setGptNotes((prev) => ({
              ...prev,
              [key]: e.target.value,
            }))
          }
          onBlur={(e) =>
            saveGPTResponse(
              e.target.value,
              GPT_BUTTONS[key],
              username,
              socialNetwork
            )
          }
          onMouseLeave={(e) =>
            saveGPTResponse(
              e.target.value,
              GPT_BUTTONS[key],
              username,
              socialNetwork
            )
          }
          autoSize
        />
        {value && (
          <Button
            onClick={() => navigator.clipboard.writeText(value)}
            className="projectNotes_response_button"
          >
            Copy text
          </Button>
        )}
      </div>
    </div>
  );
};
