import { API_URL } from "../constants/api";

export const refreshToken = async (refreshToken) => {
  const response = await fetch(`${API_URL}/auth/refreshToken`, {
    method: "GET",
    headers: {
      refreshToken,
    },
  });
  const data = await response.json();
  return data;
};

export const loginAuth = async (email, password) => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });
  const data = await response.json();

  return data;
};
