export const formatDate = (date) => {
  const [year, month, day] = date.slice(0, date.indexOf("T")).split("-");
  return `${month}/${day}/${year.slice(-2)}`;
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num;
  }
};
