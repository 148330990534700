import CheckboxIcon from "../../img/checkboxIcon";
import "./styles.scss";

import { checkboxProps } from "../../constants/checkbox.js";

export const Checkbox = ({
  id,
  onChange,
  isChecked,
  markerColor = checkboxProps.markerColor,
  borderColor = checkboxProps.borderColor,
  borderRadius = checkboxProps.borderRadius,
  markerSize = checkboxProps.markerSize,
}) => (
  <label htmlFor={`myCheckbox${id}`} className="checkbox">
    <input
      className="checkbox__input"
      type="checkbox"
      id={`myCheckbox${id}`}
      onChange={(e) => onChange(e)}
      checked={isChecked}
    />
    <CheckboxIcon
      markerColor={markerColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      markerSize={markerSize}
    />
  </label>
);
