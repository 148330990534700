export const GoogleIcon = ({ width = 25, height = 25, fill = "none" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill={fill}
  >
    <rect
      width="24"
      height="24"
      transform="translate(0.121582 0.446411)"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1616 12.7078C23.1616 11.8923 23.0884 11.1083 22.9525 10.3555H12.1216V14.8039H18.3107C18.0441 16.2414 17.2339 17.4594 16.0159 18.2748V21.1603H19.7325C21.907 19.1583 23.1616 16.2101 23.1616 12.7078Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1215 23.9462C15.2265 23.9462 17.8297 22.9165 19.7325 21.1601L16.0159 18.2746C14.9861 18.9646 13.6688 19.3724 12.1215 19.3724C9.12632 19.3724 6.59109 17.3494 5.68677 14.6312H1.84473V17.6108C3.737 21.3692 7.62609 23.9462 12.1215 23.9462Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68681 14.6315C5.45681 13.9415 5.32613 13.2044 5.32613 12.4465C5.32613 11.6885 5.45681 10.9515 5.68681 10.2615V7.28192H1.84476C1.0659 8.83442 0.621582 10.5908 0.621582 12.4465C0.621582 14.3021 1.0659 16.0585 1.84476 17.611L5.68681 14.6315Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1215 5.52027C13.81 5.52027 15.3259 6.1005 16.5177 7.24005L19.8161 3.94164C17.8245 2.08596 15.2213 0.946411 12.1215 0.946411C7.62609 0.946411 3.737 3.52346 1.84473 7.28187L5.68677 10.2614C6.59109 7.54323 9.12632 5.52027 12.1215 5.52027Z"
      fill="#EA4335"
    />
  </svg>
);