import { Path, Svg } from "@react-pdf/renderer";

export const DownloadIcon = ({ width = 8, height = 8 }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M5.64321 2.29357V5.31397L6.55057 4.40661C6.69972 4.24502 6.96075 4.24502 7.1099 4.40661C7.27149 4.55576 7.27149 4.81679 7.1099 4.96594L5.51891 6.55693C5.36975 6.71852 5.10873 6.71852 4.95958 6.55693L3.36859 4.96594C3.207 4.81679 3.207 4.55576 3.36859 4.40661C3.51774 4.24502 3.77876 4.24502 3.92792 4.40661L4.84771 5.31397V2.29357C4.84771 2.08227 5.02173 1.89583 5.24546 1.89583C5.45676 1.89583 5.64321 2.08227 5.64321 2.29357ZM2.85897 6.27105H4.11436L4.6737 6.84281C4.98444 7.15355 5.49405 7.15355 5.80479 6.84281L6.36412 6.27105H7.63194C8.06698 6.27105 8.42744 6.63151 8.42744 7.06655V7.46429C8.42744 7.91176 8.06698 8.25979 7.63194 8.25979H2.85897C2.41151 8.25979 2.06348 7.91176 2.06348 7.46429V7.06655C2.06348 6.63151 2.41151 6.27105 2.85897 6.27105ZM7.43307 6.96711C7.25906 6.96711 7.13476 7.10384 7.13476 7.26542C7.13476 7.43944 7.25906 7.56373 7.43307 7.56373C7.59466 7.56373 7.73138 7.43944 7.73138 7.26542C7.73138 7.10384 7.59466 6.96711 7.43307 6.96711Z"
      fill="#8C8C8C"
    />
  </Svg>
);
